import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { NotificationManager } from "react-notifications";

const BarometerPDF = ({
  result,
  setFormView,
  setPdfView,
  apiCall,
  isCreatePage,
  setListView,
}) => {
  const componentRef = useRef();
  const [canPrint, setCanPrint] = useState(false);
  const apiCallFUnc = async () => {
    if (isCreatePage) {
      const apiCertificateCall = await apiCall();
      if (apiCertificateCall.c_id) {
        setCanPrint(true);
      } else {
        NotificationManager.warning(
          `Certificate Not Created - ${apiCertificateCall.error}`,
          "Please Try Again Later"
        );
        setCanPrint(false);
      }
    } else {
      setCanPrint(true);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => (canPrint ? componentRef.current : null),
    documentTitle: "Barometer",
    // onBeforePrint: () => console.log("Save to Db"),
    onAfterPrint: () => setCanPrint(false),
  });

  const handleClick = async () => {
    setCanPrint(false);
    await apiCallFUnc();
  };

  useEffect(() => {
    if (canPrint) {
      handlePrint();
    }
  }, [canPrint, handlePrint]);

  const { firstPage, secondPage, thirdPage, fourthPage, fifthPage } = result;
  const header = () => {
    return (
      <div className="pb-4">
        <img src={fifthPage.logo} className="w-full" alt="logo" />
      </div>
    );
  };
  const rangeWords = thirdPage.range.split(";");
  const signature = () => {
    return (
      <>
        <table className="certificate w-10/12 m-auto border-collapse">
          <tbody>
            <tr>
              <td className="w-1/6 max-h-12">
                <b>Tested By</b>
              </td>
              <td className="w-2/6"></td>
              <td className="w-1/6">
                <b>Approved By</b>
              </td>
              <td className="w-2/6"></td>
            </tr>
            <tr>
              <td className="max-h-12">
                <b>Signature</b>
              </td>
              <td>
                <img
                  src={fifthPage.signature1}
                  className="max-h-10 m-2"
                  alt="sign"
                />
              </td>
              <td>
                <b>Signature</b>
              </td>
              <td>
                <img
                  src={fifthPage.signature2}
                  className="max-h-10 m-2"
                  alt="sign"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br className="br" />
      </>
    );
  };

  const jobDetails = () => {
    return (
      <table className="certificate w-10/12 m-auto border-collapse">
        <tbody>
          <tr>
            <td colSpan={2} className="w-1/5 bg-slate-200">
              <b>Job No</b>
            </td>
            <td colSpan={2} className="w-30">{secondPage.jobNo}</td>
            <td colSpan={3} className="w-1/5 bg-slate-200">
              <b>Test Date</b>
            </td>
            <td colSpan={2}>{thirdPage.testDate}</td>
          </tr>
          <tr>
            <td colSpan={2} className="bg-slate-200">
              <b>Certificate</b>
            </td>
            <td colSpan={2}>{secondPage.certificate}</td>
            <td colSpan={3} className="bg-slate-200">
              <b>Test Due</b>
            </td>
            <td colSpan={2}>{thirdPage.dueDate}</td>
          </tr>
          <tr>
            <td colSpan={2} className="bg-slate-200">
              <b>Procedure</b>
            </td>
            <td colSpan={2}>{thirdPage.systemCode}</td>
            <td colSpan={3} className="bg-slate-200">
              <b>Location</b>
            </td>
            <td colSpan={2}>{secondPage.location}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const test = () => {
    return (
      <table className="certificate w-10/12 m-auto border-collapse text-center">
        <tbody>
          <tr className="text-center bg-slate-300">
            <td colSpan={8}>
              <b> CALIBRATION DETAILS</b>
            </td>
          </tr>
          <tr>
            <td className="w-1/4">
              <b>Reference Reading </b>
            </td>
            <td className="w-1/4">
              <b>UUT Reading </b>
            </td>

            <td className="w-1/4">
              <b>Error </b>
            </td>
          </tr>

          {fourthPage.testData.map((list, index) => (
            <tr key={index}>
              <td>{list.referenceReading}</td>
              <td>{list.UUTReading}</td>
              <td>{list.error}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const footer = (pgnumber = 1) => {
    return (
      <>
        <div className="w-10/12 m-auto space-y-2 text-xs pb-4">
          <div>
            <div className="flex justify-between">
              <p>Ref No: MS-QAT-QHSEMSP 013.04</p>
              <p>Rev No: 00</p>
              <p>Issue No: 01</p>
              <p>Effective Date: {thirdPage.testDate}</p>
            </div>
            <p className="text-center">page {pgnumber}/2</p>
          </div>
          <div>
            <hr className="line" />
          </div>
          <div>
            <p className="text-center">
              Tel: +97149919370 | Fax: +97167411427 | Dubai,UAE | e-mail:
              lab.dubai@mastersystems.com
            </p>
            <p className="text-center">web: www.mastersystems.com</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="border border-slate-300">
          <div ref={componentRef} className="w-full bg-white">
            <div className="flex flex-col justify-between min-h-screen">
              {header()}
              <div>
                <table className="certificate w-10/12 m-auto border-collapse">
                  <thead className="mb-4">
                    <tr>
                      <th colSpan={9} className="bg-slate-400">
                        <span className="text-xl font-bold">
                          CALIBRATION CERTIFICATE
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={9} className="bg-slate-200">
                        <span className="text-base font-bold">BAROMETER</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={9} className="border-none">
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="w-1/5 bg-slate-200">
                        <b>Client</b>
                      </td>
                      <td colSpan={2} className="w-30">
                        <b>{firstPage.client}</b>
                      </td>
                      <td colSpan={3} className="w-1/5 bg-slate-200">
                        <b>Vessel</b>
                      </td>
                      <td colSpan={2}>
                        <b>{firstPage.vessel}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="bg-slate-200">
                        <b>MMSI Number</b>
                      </td>
                      <td colSpan={2}>
                        <b>{firstPage.MMSINumber}</b>
                      </td>
                      <td colSpan={3} className="bg-slate-200">
                        <b>IMO Number</b>
                      </td>
                      <td colSpan={2}>
                        <b>{firstPage.IMONumber}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br className="br" />

                {jobDetails()}
                <br className="br" />

                <table className="certificate w-10/12 m-auto border-collapse">
                  <tbody>
                    <tr>
                      <td colSpan={9} className="text-center bg-slate-300">
                        <b>UNIT UNDER TEST SPECIFICATIONS</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="bg-slate-200">
                        <b>Description</b>
                      </td>
                      <td colSpan={7}>BAROMETER</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="bg-slate-200">
                        <b>Make</b>
                      </td>
                      <td colSpan={2}>{thirdPage.make}</td>
                      <td colSpan={3} className="bg-slate-200">
                        <b>Model</b>
                      </td>
                      <td colSpan={2}>{thirdPage.model}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="bg-slate-200">
                        <b>Serial No</b>
                      </td>
                      <td colSpan={2}>{thirdPage.serial}</td>
                      <td colSpan={3} className="bg-slate-200">
                        <b>Range</b>
                      </td>
                      <td colSpan={2}>
                        {rangeWords.map((words) => (
                          <p key={words}> {words}</p>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9} className="border-none">
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9} className="text-center bg-slate-300">
                        <b>ENVIRONMENTAL CONDITION</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>Temperature</b>
                      </td>
                      <td colSpan={2}>{fourthPage.temperature}</td>
                      <td colSpan={2}>
                        <b>Relative Humidity</b>
                      </td>
                      <td colSpan={2}>{fourthPage.relativeHumidity}</td>
                    </tr>
                  </tbody>
                </table>
                <br className="br" />

                <table className="certificate w-10/12 m-auto border-collapse text-center">
                  <tbody>
                    <tr>
                      <td colSpan={5} className="bg-slate-300">
                        <b>TRACEABILITY OF REFERENCE EQUIPMENT</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-1/4 ">
                        <b>Reference Instrument </b>
                      </td>
                      <td className="w-1/4">
                        <b>Make</b>
                      </td>
                      <td className="w-1/6">
                        <b>Certificate No</b>
                      </td>
                      <td className="w-1/6">
                        <b>Serial No</b>
                      </td>
                    </tr>
                    {fourthPage.testEquipment.map((list) => (
                      <tr key={list.referenceInstrument}>
                        <td>{list.referenceInstrument}</td>
                        <td>{list.make}</td>
                        <td>{list.certNo}</td>
                        <td>{list.SerialNo}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br className="br" />
              </div>
              <div className="w-10/12 m-auto">
                <p>
                  This certificate confirms that the above-mentioned BAROMETER
                  has been tested in {secondPage.site} , {secondPage.location}.
                  The system is fully operational and in compliance with the
                  manufacturer’s original specifications.
                </p>
                <p>
                  The System’s operation were checked and verified as
                  functional. This certificate has been issued in accordance
                  with Standard Manufacturer Approved Procedures. This
                  certificate shall not be reproduced except in full, without
                  the written approval of Master Systems laboratory.
                </p>
              </div>
              <br className="br" />

              {fourthPage.testData.length > 1 ? (
                <>
                  <br className="br" />
                  {signature()}
                  <div className="grow">
                    <br className="br" />
                  </div>
                  {footer(1)}
                </>
              ) : (
                <>
                  {test()}
                  <br className="br" />
                  {signature()}
                  <div className="grow">
                    <br className="br" />
                  </div>
                  {footer(1)}
                </>
              )}
            </div>

            {fourthPage.testData.length > 1 ? (
              <>
                <div className="page-break" />
                <div className="flex flex-col justify-end min-h-screen">
                  <div>
                    {header()}
                    <br className="br" />
                    {jobDetails()}
                    <br className="br" />
                    {test()}
                    <br className="br" />
                    <br className="br" />
                    {signature()}
                  </div>

                  <div className="grow">
                    <br className="br" />
                  </div>
                  {footer(fourthPage.testData.length > 1 ? 2 : 1)}
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div className="flex justify-center gap-4 m-6 p-6">
          {isCreatePage ? (
            <button
              onClick={() => {
                setFormView(true);
                setPdfView(false);
              }}
              type="button"
              className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Previous Page
            </button>
          ) : (
            <button
              onClick={() => {
                setListView(true);
                setPdfView(false);
              }}
              type="button"
              className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Close
            </button>
          )}
          <button
            onClick={handleClick}
            type="button"
            className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
          >
            {isCreatePage ? "  Print PDF & Save" : "Print PDF"}
          </button>
        </div>
      </div>
    </>
  );
};
export default BarometerPDF;
