import { postRequest } from "./request";

export function UpdatePasswordApi(dataObject) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return postRequest({
    path: `update-password`,
    data: {
      newPassword: dataObject.newPassword,
      confirmNewPassword: dataObject.confirmNewPassword,
    },
    token: userData.token,
  }).then((response) => {
    return response ? response : undefined;
  });
}
