import { postRequest } from "./request";

export function BlockVessel(dataObject) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return postRequest({
    path: `block-vessel`,
    token: userData.token,
    data: {
      v_id: dataObject,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}
