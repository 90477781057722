import { AlcoholDetection } from "./forms/AlcoholDetection";
import { FifteenPPMMonitorForm } from "./forms/FifteenPPMMonitorForm";
import { FireAlarmSystemForm } from "./forms/FireAlarmSystemForm";
import { FixedGasDetection } from "./forms/FixedGasDetection";
import { PortableGasDetector } from "./forms/PortableGasDetector";
import { MultigasDetection } from "./forms/MultigasDetection";
import { Ammeter } from "./forms/Ammeter";
import { Anemometer } from "./forms/Anemometer";
import { Barometer } from "./forms/Barometer";

const formComponents = {
  fifteen_ppm_monitor: FifteenPPMMonitorForm,
  fire_alarm_system: FireAlarmSystemForm,
  portable_gas_detector: PortableGasDetector,
  alcohol_detection: AlcoholDetection,
  fixed_gas_detection: FixedGasDetection,
  multi_gas_detection: MultigasDetection,
  ammeter: Ammeter,
  anemometer: Anemometer,
  barometer: Barometer,
};
export default formComponents;
