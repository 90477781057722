import { postRequest } from "./request";

export function CertificateOnload(dataObject) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return postRequest({
    path: `get-certificate-onload`,
    token: userData.token,
    data: {
      user_id: userData.userid,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}
