import React, { useContext, useEffect, useState } from "react";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import { MyContext } from "../dashboard/Dashboard";

import { FormPageOne } from "../../components/FormPageOne";
import { FormPageThree } from "../../components/FormPageThree";
import { FormPageTwo } from "../../components/FormPageTwo";

import { CertificateOnload } from "../../api/CertificateOnload";
import { CertificateCreate } from "../../api/certificationCreate";

import formComponents from "../../formComponents";
import { WeighingScaleForm } from "../../forms/WeighingScaleForm";


export const CreateCertificate = () => {
  const { setValue } = useContext(MyContext);
  const [result, setResult] = useState("");
  const [clientAndVessel, setClientAndVessel] = useState({});
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formNumber, setFormNumber] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const data = await CertificateOnload();
      setClientAndVessel(data);
    }
    fetchData();
    setValue("Create Certificate");
  }, []);

  const certificateCreateApi = async () => {
    const { firstPage, secondPage, thirdPage, fourthPage } = result;
    const payload = {
      jsonObject: fourthPage,
      ...firstPage,
      ...secondPage,
      ...thirdPage,
    };
    const data = await CertificateCreate(payload);

    return data;
  };
  return (
    <>
      <div className="px-6 pt-6 2xl:container">
        {/* <WeighingScaleForm
          result={result}
          setResult={setResult}
          setShow1={setShow1}
          setShow2={setShow2}
          setShow3={setShow3}
          setShowForm={setShowForm}
          apiCall={certificateCreateApi}
        /> */}

        <div className={show1 ? "block" : "hidden"}>
          <FormPageOne
            result={result}
            clientAndVessel={clientAndVessel}
            setResult={setResult}
            setShow1={setShow1}
            setShow2={setShow2}
            setShow3={setShow3}
          />
        </div>
        <div className={show2 ? "block" : "hidden"}>
          <FormPageTwo
            result={result}
            locationAndSite={clientAndVessel}
            setResult={setResult}
            setShow1={setShow1}
            setShow2={setShow2}
            setShow3={setShow3}
          />
        </div>
        <div className={show3 ? "block" : "hidden"}>
          <FormPageThree
            result={result}
            systemAndSpecification={clientAndVessel}
            setResult={setResult}
            setShow1={setShow1}
            setShow2={setShow2}
            setShow3={setShow3}
            setShowForm={setShowForm}
            setFormNumber={setFormNumber}
          />
        </div>
        <div className={formNumber && showForm ? "block" : "hidden"}>
          {Object.keys(formComponents).map((key) => {
            const FormComponent = formComponents[key];
            return (
              formNumber === key && (
                <div
                  key={key}
                  className={formNumber === key ? "block" : "hidden"}
                >
                  <FormComponent
                    result={result}
                    setResult={setResult}
                    setShow1={setShow1}
                    setShow2={setShow2}
                    setShow3={setShow3}
                    setShowForm={setShowForm}
                    apiCall={certificateCreateApi}
                  />
                </div>
              )
            );
          })}

        </div>
      </div>
      <NotificationContainer />
    </>
  );
};
