import { postRequest } from "./request";

export function CreateVesselApi(dataObject) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return postRequest({
    path: `create-vessel`,
    token: userData.token,
    data: {
      vname: dataObject.vesselName,
      c_id: dataObject.client,
      imo_number: dataObject.IMONumber,
      mmsi_number: dataObject.MMSI,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}
