import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Preloading } from "./Preloading";

export const FormPageOne = ({
  setResult,
  clientAndVessel,
  result,
  setShow1,
  setShow2,
  setShow3,
}) => {
  const [data, setData] = useState();
  const [clients, setClients] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [filterVessels, setFilterVessels] = useState([]);

  const [MMSINumber, setMMSINumber] = useState("");
  const [IMONumber, setIMONumber] = useState("");

  useEffect(() => {
    if (clientAndVessel && clientAndVessel.clients && clientAndVessel.vessels) {
      setClients(clientAndVessel.clients);
      setVessels(clientAndVessel.vessels);
    }
  }, [clientAndVessel]);

  const sortVessels = (clientId) => {
    let vesselId = vessels;
    for (let i = 0; i < clients.length; i++) {
      if (Number(clients[i].c_id) === Number(clientId)) {
        setData(() => ({
          ...data,
          client: clients[i].client_name,
          client_id: Number(clientId),
          vessel: null,
          vessel_id: null,
        }));
      }
    }
    const filteredArray = vesselId.filter(
      (item) => Number(item.c_id) === Number(clientId)
    );
    setFilterVessels(filteredArray);
  };

  const loadVesselsDetails = (vesselId) => {
    for (let i = 0; i < filterVessels.length; i++) {
      if (Number(filterVessels[i].v_id) === Number(vesselId)) {
        setMMSINumber(filterVessels[i].mmsi_number);
        setIMONumber(filterVessels[i].imo_number);
        setData(() => ({
          ...data,
          vessel: filterVessels[i].vessel_name,
          vessel_id: Number(vesselId),
        }));
      }
    }
  };

  const proceedHandler = () => {
    if (!!data && !!data.client && !!data.vessel) {
      setResult(() => ({
        ...result,
        firstPage: {
          ...data,
          MMSINumber: MMSINumber,
          IMONumber: IMONumber,
        },
      }));
      setShow1(false);
      setShow2(true);
      setShow3(false);
    } else {
      NotificationManager.warning(
        "Refresh and Please select Client and Vessel",
        "Incomplete Form"
      );
    }
  };
  return (
    <>
      {clientAndVessel && clientAndVessel.clients && clientAndVessel.vessels ? (
        <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white space-y-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Select Client</span>
              </label>
              <select
                onChange={(e) => {
                  sortVessels(e.target.value);
                }}
                defaultValue="0"
                className="select select-bordered"
              >
                <option value="0" disabled>
                  Pick the Client
                </option>
                {!clients.length ? (
                  <option value="0" disabled>
                    Please sync and try again
                  </option>
                ) : (
                  clients.map((option) => (
                    <option key={option.c_id} value={option.c_id}>
                      {option.client_name}
                    </option>
                  ))
                )}
              </select>
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Select Vessel</span>
              </label>
              <select
                onChange={(e) => {
                  loadVesselsDetails(e.target.value);
                }}
                defaultValue="0"
                className="select select-bordered"
              >
                <option value="0" disabled>
                  Pick a Vessel
                </option>
                {!filterVessels.length ? (
                  <option value="0" disabled>
                    Please select client first
                  </option>
                ) : (
                  filterVessels.map((option) => (
                    <option key={option.v_id} value={option.v_id}>
                      {option.vessel_name}
                    </option>
                  ))
                )}
              </select>
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">MMSI Number</span>
              </label>
              <input
                type="text"
                value={MMSINumber}
                disabled
                className="input input-bordered w-full !border-gray-300"
              />
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">IMO Number</span>
              </label>
              <input
                type="text"
                value={IMONumber}
                disabled
                className="input input-bordered w-full !border-gray-300"
              />
            </div>
          </div>

          <div className="flex space-x-2 justify-end">
            <button
              onClick={proceedHandler}
              type="button"
              className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Proceed
            </button>
          </div>
        </div>
      ) : (
        <Preloading />
      )}
    </>
  );
};
