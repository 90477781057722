import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import close from "../assets/images/close.png";
import FireAlarmSystem from "../pdf/FireAlarmSystem";

export const FireAlarmSystemForm = ({
  setResult,
  result,
  setShow1,
  setShow2,
  setShow3,
  setShowForm,
  apiCall,
}) => {
  const [formView, setFormView] = useState(true);
  const [pdfView, setPdfView] = useState(false);

  const [data, setData] = useState("");

  const [testData, setTestData] = useState([{}]);
  const [testEquipment, setTestEquipment] = useState([
    { referenceInstrument: "Smoke Spray" },
    { referenceInstrument: "Heat Gun" },
  ]);

  const [smokeSum, setSmokeSum] = useState("");
  const [heatSum, setHeatSum] = useState("");
  const [callPointSum, setCallPointSum] = useState("");

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...testData];
    if (
      name === "ManualCallPoint" ||
      name === "HeatDetector" ||
      name === "SmokeDetector"
    ) {
      const newValue = Number(value);
      list[index][name] = newValue;
      setTestData(list);
    } else {
      list[index][name] = value;
      setTestData(list);
    }
  };

  const sumHandler = (equipment, setSum) => {
    const sum = testData.map((i) => i[equipment]).reduce((a, b) => a + b);
    setSum(sum);
  };

  const handleTestEquipmentChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...testEquipment];
    list[index][name] = value;
    setTestEquipment(list);
  };

  const handleServiceDelete = (e, index) => {
    const newtestData = testData.filter((v, i) => i !== index);
    setTestData(newtestData);
  };

  const handleServiceAdd = () => {
    setTestData([...testData, {}]);
  };

  const previousHandler = () => {
    setResult(() => ({
      ...result,
      fourthPage: { ...data, testData, testEquipment },
    }));
    setShow1(false);
    setShow2(false);
    setShow3(true);
    setShowForm(false);
  };

  const submitHandler = () => {
    const serviceError = testEquipment
      .map((list) => {
        if (
          list.referenceInstrument &&
          list.make &&
          list.type &&
          list.serial &&
          list.LotExpiry
        ) {
          return true;
        } else {
          return false;
        }
      })
      .every((val) => val === true);
    const testEquipmentError = testData
      .map((list) => {
        if (
          list.ManualCallPoint &&
          list.HeatDetector &&
          list.SmokeDetector &&
          list.ZONE
        ) {
          return true;
        } else {
          return false;
        }
      })
      .every((val) => val === true);
    if (
      data !== "" &&
      data.temperature !== undefined &&
      data.relativeHumidity !== undefined &&
      data.temperature !== "" &&
      data.relativeHumidity !== "" &&
      data.permission24v !== "" &&
      data.permission230V !== "" &&
      data.permissionBattery !== "" &&
      data.permission24v !== undefined &&
      data.permission230V !== undefined &&
      data.permissionBattery !== undefined &&
      serviceError &&
      testEquipmentError
    ) {
      setFormView(false);
      setPdfView(true);
      setResult(() => ({
        ...result,
        fourthPage: {
          ...data,
          smokeSum,
          heatSum,
          callPointSum,
          testData,
          testEquipment,
        },
      }));
    } else {
      NotificationManager.warning(
        "Please complete the form",
        "Incomplete Form"
      );
    }
  };
  return (
    <>
      <div className={formView ? "block" : "hidden"}>
        <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white space-y-10">
          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">
              ENVIRONMENTAL CONDITION
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Temperature</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setData(() => ({ ...data, temperature: e.target.value }));
                  }}
                  placeholder="Enter Temperature"
                  className="input input-bordered w-full"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Relative Humidity</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setData(() => ({
                      ...data,
                      relativeHumidity: e.target.value,
                    }));
                  }}
                  placeholder="Enter Relative Humidity"
                  className="input input-bordered w-full"
                />
              </div>
            </div>
          </div>

          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">
              TRACEABILITY OF MASTER EQUIPMENT
            </h2>
            <div>
              {testEquipment.map((equipment, index) => (
                <div key={"equipment" + index} className="flex justify-between">
                  <div className="grid grid-cols-2 md:grid-cols-5 gap-6">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Reference Instrument</span>
                      </label>
                      <input
                        defaultValue={equipment.referenceInstrument}
                        type="text"
                        onChange={(e) => { }}
                        name="referenceInstrument"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Make</span>
                      </label>
                      <input
                        value={equipment.make || ""}
                        type="text"
                        onChange={(e) => handleTestEquipmentChange(e, index)}
                        name="make"
                        placeholder="Enter Make"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Type</span>
                      </label>
                      <input
                        value={equipment.type || ""}
                        type="text"
                        onChange={(e) => handleTestEquipmentChange(e, index)}
                        name="type"
                        placeholder="Enter Type"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Serial No.</span>
                      </label>
                      <input
                        value={equipment.serial || ""}
                        type="text"
                        onChange={(e) => handleTestEquipmentChange(e, index)}
                        name="serial"
                        placeholder="Enter Serial No."
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">LOT. Expiry</span>
                      </label>
                      <input
                        value={equipment.LotExpiry || ""}
                        type="text"
                        onChange={(e) => handleTestEquipmentChange(e, index)}
                        name="LotExpiry"
                        placeholder="Enter LOT. Expiry"
                        className="input input-bordered w-full"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">TEST DATA </h2>
            <div>
              {testData.map((tdata, index) => (
                <div
                  key={"equipment-" + index}
                  className="flex justify-between"
                >
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">ZONE</span>
                      </label>
                      <input
                        value={tdata.ZONE || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="ZONE"
                        placeholder="Enter ZONE"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Smoke Detector</span>
                      </label>
                      <input
                        value={tdata.SmokeDetector || ""}
                        type="number"
                        min={0}
                        onBlur={() => sumHandler("SmokeDetector", setSmokeSum)}
                        onChange={(e) => handleServiceChange(e, index)}
                        name="SmokeDetector"
                        placeholder="Enter Smoke Detector value"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Heat Detector</span>
                      </label>
                      <input
                        value={tdata.HeatDetector || ""}
                        type="number"
                        min={0}
                        onBlur={() => sumHandler("HeatDetector", setHeatSum)}
                        onChange={(e) => handleServiceChange(e, index)}
                        name="HeatDetector"
                        placeholder="Enter Heat Detector Value"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Manual Call Point</span>
                      </label>
                      <input
                        value={tdata.ManualCallPoint || ""}
                        type="number"
                        min={0}
                        onBlur={() =>
                          sumHandler("ManualCallPoint", setCallPointSum)
                        }
                        onChange={(e) => handleServiceChange(e, index)}
                        name="ManualCallPoint"
                        placeholder="Enter Manual Call Point"
                        className="input input-bordered w-full"
                      />
                    </div>
                  </div>
                  {index === 0 ? null : (
                    <div className="flex flex-wrap content-center">
                      <img
                        src={close}
                        onClick={(e) => {
                          handleServiceDelete(e, index);
                        }}
                        className="h-6 w-6 cursor-pointer"
                        alt="Search Icon"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-end mb-2 gap-2">
              <button
                type="button"
                onClick={handleServiceAdd}
                className="inline-block mt-4 px-8 py-2 bg-green-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
              >
                <span>Add Zone</span>
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 font-bold text-center">
              <div className="form-control w-full">
                <label className="label"></label>
              </div>
              <div className="form-control w-full">
                <label className="label">SMOKE DETECTOR SUM </label>
                <p>{smokeSum}</p>
              </div>
              <div className="form-control w-full">
                <label className="label">HEAT DETECTOR SUM </label>
                <p>{heatSum}</p>
              </div>
              <div className="form-control w-full">
                <label className="label">MANUAL CALL POINT SUM </label>
                <p>{callPointSum}</p>
              </div>
            </div>
          </div>
          {/* Passed */}

          <div>
            <div className="flex justify-between">
              <div className="grid grid-cols-2 md:grid-cols-4 gap-6 font-bold text-center">
                <div className="form-control w-full">
                  <label className="label">Power Supply</label>
                </div>
                <div className="form-control w-full">
                  <label className="label">Main Power ( 230 V AC) </label>
                  <select
                    onChange={(e) => {
                      setData(() => ({
                        ...data,
                        permission230V: e.target.value,
                      }));
                    }}
                    value={
                      data !== undefined
                        ? data.permission230V !== undefined
                          ? data.permission230V
                          : ""
                        : ""
                    }
                    className="select select-bordered"
                  >
                    <option disabled value="">
                      Pick the Permission
                    </option>
                    <option value="Passed">Passed</option>
                    <option value="Failed">Failed</option>
                  </select>
                </div>
                <div className="form-control w-full">
                  <label className="label">Backup Power ( 24v DC) </label>
                  <select
                    onChange={(e) => {
                      setData(() => ({
                        ...data,
                        permission24v: e.target.value,
                      }));
                    }}
                    value={
                      data !== undefined
                        ? data.permission24v !== undefined
                          ? data.permission24v
                          : ""
                        : ""
                    }
                    className="select select-bordered"
                  >
                    <option disabled value="">
                      Pick the Permission
                    </option>
                    <option value="Passed">Passed</option>
                    <option value="Failed">Failed</option>
                  </select>{" "}
                </div>
                <div className="form-control w-full">
                  <label className="label">Backup Battery</label>
                  <select
                    onChange={(e) => {
                      setData(() => ({
                        ...data,
                        permissionBattery: e.target.value,
                      }));
                    }}
                    value={
                      data !== undefined
                        ? data.permissionBattery !== undefined
                          ? data.permissionBattery
                          : ""
                        : ""
                    }
                    className="select select-bordered"
                  >
                    <option disabled value="">
                      Pick the Permission
                    </option>
                    <option value="Passed">Passed</option>
                    <option value="Failed">Failed</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div></div>

          <div className="flex justify-end gap-4">
            <button
              onClick={previousHandler}
              type="button"
              className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Previous
            </button>

            <button
              onClick={submitHandler}
              type="button"
              className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
      {pdfView ? (
        <div>
          <FireAlarmSystem
            result={result}
            setFormView={setFormView}
            setPdfView={setPdfView}
            apiCall={apiCall}
            isCreatePage={true}
          />
        </div>
      ) : null}
    </>
  );
};
