import { postRequest } from "./request";

export function UserListOnload(dataObject) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return postRequest({
    path: `get-users`,
    token: userData.token,
  }).then((response) => {
    return response ? response : undefined;
  });
}
