import React, { useContext, useEffect, useState } from "react";
import {
  NotificationManager,
} from "react-notifications";
import { MyContext } from "../dashboard/Dashboard";

import spinner from "../../assets/images/spinner.gif";
import { UpdatePasswordApi } from "../../api/UpadatePassword";

const Profile = () => {
  const { setValue } = useContext(MyContext);
  const [user, setUser] = useState("");

  const [password, setPassword] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);

  useEffect(() => {
    setValue("Profile");
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUser(userData.user);
  }, [])

  const submitPassword = async (e) => {
    e.preventDefault();
    if (!password.newPassword || !password.confirmNewPassword) {
      NotificationManager.warning("Please provide details", "Incomplete Data");
    } else if (password.newPassword !== password.confirmNewPassword) {
      NotificationManager.warning(
        "Please provide both password same",
        "Password Not Match"
      );
    } else {
      setLoadingPassword(true);
      try {
        const response = await UpdatePasswordApi(password);
        setLoadingPassword(false);
        if (response.data) {
          // if response do something
          console.log(response);
        } else {
          NotificationManager.error(
            response.status_message,
            "Failed to Update Password"
          );
        }
      } catch (error) {
        console.log("Password Updation Failed: " + error);
        setLoadingPassword(false);
        NotificationManager.error(
          "Network error. Please check your connection",
          "Failed to Update Password"
        );
      }
    }
  };



  return (
    <>
      <div className="px-6 pt-6 2xl:container">
        <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white">
          <div className="overflow-x-auto w-full gap-y-6">
            <table className="border-none w-1/2">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>:</td>
                  <td>{user.name}</td>
                </tr>
                <tr>
                  <td>Username</td>
                  <td>:</td>
                  <td>{user.username}</td>
                </tr>
                <tr>
                  <td>Designation</td>
                  <td>:</td>
                  <td>{user.designation}</td>
                </tr>
              </tbody>
            </table>



            <div className="divider">Update Password</div>
            <form className="grid grid-cols-1 md:grid-cols-2 flex  gap-4 md:gap-6">
              <div>
                <label
                  htmlFor="newPassword"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  New Password
                </label>
                <input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter New Password"
                  onChange={(e) =>
                    setPassword(() => ({
                      ...password,
                      newPassword: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="confirmNewPassword"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm New Password
                </label>
                <input
                  type="password"
                  name="confirmNewPassword"
                  id="confirmNewPassword"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter New Password Again"
                  onChange={(e) =>
                    setPassword(() => ({
                      ...password,
                      confirmNewPassword: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <button
                onClick={submitPassword}
                disabled={loadingPassword ? "disabled" : ""}
                className="w-1/2 mx-auto col-span-2 text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 cursor-pointer cursor-pointer"
              >
                <img
                  className={
                    (loadingPassword ? "inline" : "hidden") + " mr-2 max-h-4"
                  }
                  src={spinner}
                  alt="loading... "
                />
                Update Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile;
