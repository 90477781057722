import { postRequest } from "./request";

export function CreateClientApi(dataObject) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return postRequest({
    path: `create-client`,
    token: userData.token,

    data: {
      cname: dataObject.clientName,
      caddress: dataObject.contactEmail,
      cperson: dataObject.contactName,
      cnumber: dataObject.contactPhoneNum,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}
