import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { BlockUser } from "../../api/blockUser";
import { UserListOnload } from "../../api/UserListOnLoad";
import { Preloading } from "../../components/Preloading";
import { MyContext } from "../dashboard/Dashboard";

const UserList = () => {
  const { setValue } = useContext(MyContext);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue("Users");
    async function fetchData() {
      setLoading(true);
      try {
        const  data  = await UserListOnload();
        setLoading(false);

        if (!data) {
          NotificationManager.warning(
            "Couldn't find any response",
            "Api Error"
          );
        } else {
          setUserList(data.users);
        }
      } catch (error) {
        setLoading(false);

        NotificationManager.error(
          "Network error. Please check your connection",
          "Failed to collect data"
        );
      }
    }
    fetchData();
  }, [setValue]);

  const blockHandler = async (id) => {
    const response = await BlockUser(id);
    // do change according to response
  };

  return (
    <>
      {loading && <Preloading />}
      {userList && (
        <>
          <div className="px-6 pt-6 2xl:container">
            <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white">
              <div className="flex justify-end mb-4">
                <Link to="/dashboard/create-user">
                  <button className="btn btn-sm">
                    <span className="text-xs">Create New User</span>
                  </button>
                </Link>
              </div>
              <div className="overflow-x-auto w-full">
                <table className="table w-full">
                  {/* head */}
                  <thead>
                    <tr>
                      <th>Name/Username</th>
                      <th>Designation</th>
                      <th>Departments</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.map((list) => (
                      <tr key={list.m_id}>
                        <td>
                          <div className="flex items-center space-x-3">
                            <div>
                              <div className="font-bold">{list.name}</div>
                              <div className="text-sm opacity-50">
                                {list.role === "1" && "Admin"}
                                {list.role === "2" && "Engineer"}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {list.designation}
                          {/* <span className="badge badge-ghost badge-sm">Desktop Support Technician</span> */}
                        </td>
                        <td>{list.department}</td>
                        <th>
                          <button
                            className="btn btn-ghost btn-xs"
                            onClick={() => {
                              blockHandler(list.m_id);
                            }}
                          >
                            Block
                          </button>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                  {/* foot */}
                  <tfoot>
                    <tr>
                      <th>Name/Username</th>
                      <th>Designation</th>
                      <th>Departments</th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserList;
