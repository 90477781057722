import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";

import close from "../assets/images/close.png";

export const FormPageTwo = ({
  setResult,
  locationAndSite,
  result,
  setShow1,
  setShow2,
  setShow3,
}) => {
  const [data, setData] = useState({ site: "", location: "" });
  const [locations, setLocations] = useState([]);
  const [sites, setSites] = useState([]);
  const [customSite, setCustomSite] = useState(false);
  const [customLocation, setCustomLocation] = useState(false);

  useEffect(() => {
    if (locationAndSite && locationAndSite.locations) {
      setLocations(locationAndSite.locations);
    }
    if (locationAndSite && locationAndSite.sites) {
      setSites(locationAndSite.sites);
    }
  }, [locationAndSite]);

  const previousHandler = () => {
    setResult(() => ({ ...result, secondPage: { ...data } }));
    setShow1(true);
    setShow2(false);
    setShow3(false);
  };

  const proceedHandler = () => {
    if (
      !!data &&
      !!data.jobNo &&
      !!data.site &&
      !!data.location
      //true
    ) {
      setResult(() => ({ ...result, secondPage: { ...data } }));
      setShow1(false);
      setShow2(false);
      setShow3(true);
    } else {
      NotificationManager.warning(
        "Please complete the form",
        "Incomplete Form"
      );
    }
  };

  return (
    <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white space-y-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Job Number</span>
          </label>
          <input
            type="text"
            onChange={(e) => {
              e.target.value = ("" + e.target.value).toUpperCase();
              setData(() => ({ ...data, jobNo: e.target.value }));
            }}
            onBlur={(e) => {
              setData(() => ({ ...data, certificate: e.target.value }));
            }}
            placeholder="Enter Job Number"
            className="input input-bordered w-full"
          />
        </div>

        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Select Test Site</span>
          </label>
          <select
            onChange={(e) => {
              data.site = "";
              if (e.target.value === "customOption") {
                setCustomSite(true);
              } else {
                let site_id = "";
                for (var i = 0; i < sites.length; i++) {
                  if (sites[i].site_name === e.target.value) {
                    site_id = sites[i].site_id;
                  }
                }
                setData(() => ({
                  ...data,
                  site: e.target.value,
                  site_id: site_id,
                }));
              }
            }}
            value={
              data !== undefined
                ? data.site !== undefined
                  ? data.site
                  : ""
                : ""
            }
            className={
              (customSite ? "hidden" : "block") + " select select-bordered"
            }
          >
            <option disabled value="">
              Pick the Site
            </option>
            {!sites.length ? (
              <option value="0" disabled>
                Please sync and try again
              </option>
            ) : (
              sites.map((option) => (
                <option key={option.site_id} value={option.site_name}>
                  {option.site_name}
                </option>
              ))
            )}
            <option value="customOption">Custom Site</option>
          </select>

          <div className="w-full flex justify-end items-center relative">
            <input
              placeholder="Enter Test Site"
              onChange={(e) => {
                setData(() => ({ ...data, site: e.target.value, site_id: "" }));
              }}
              value={
                data !== undefined
                  ? data.site !== undefined || data.site !== null
                    ? data.site
                    : ""
                  : ""
              }
              className={
                (customSite ? "block" : "hidden") +
                " input input-bordered w-full"
              }
            />
            <img
              src={close}
              onClick={(e) => {
                data.site = "";
                setCustomSite(false);
              }}
              className={
                (customSite ? "block" : "hidden") +
                " absolute mr-2 w-6 cursor-pointer"
              }
              alt="Search Icon"
            />
          </div>
        </div>

        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Certificate</span>
          </label>
          <input
            type="text"
            value={
              data !== undefined
                ? data.certificate !== undefined
                  ? data.certificate
                  : ""
                : ""
            }
            disabled
            className="input input-bordered w-full !border-gray-300"
          />
        </div>

        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Select Location</span>
          </label>
          <select
            onChange={(e) => {
              data.location = "";
              if (e.target.value === "customOption") {
                setCustomLocation(true);
              } else {
                let loc_id = "";
                for (var i = 0; i < locations.length; i++) {
                  if (locations[i].location_name === e.target.value) {
                    loc_id = locations[i].loc_id;
                  }
                }
                setData(() => ({
                  ...data,
                  location: e.target.value,
                  loc_id: loc_id,
                }));
              }
            }}
            value={
              data !== undefined
                ? data.location !== undefined
                  ? data.location
                  : ""
                : ""
            }
            className={
              (customLocation ? "hidden" : "block") + " select select-bordered"
            }
          >
            <option disabled value="">
              Pick the Location
            </option>
            {!locations.length ? (
              <option value="0" disabled>
                Please sync and try again
              </option>
            ) : (
              locations.map((option) => (
                <option key={option.loc_id} value={option.location_name}>
                  {option.location_name}
                </option>
              ))
            )}
            <option value="customOption">Custom Location</option>
          </select>
          <div className="w-full flex justify-end items-center relative">
            <input
              placeholder="Enter Location"
              onChange={(e) => {
                setData(() => ({
                  ...data,
                  location: e.target.value,
                  loc_id: "",
                }));
              }}
              value={
                data !== undefined
                  ? data.location !== undefined || data.location !== null
                    ? data.location
                    : ""
                  : ""
              }
              className={
                (customLocation ? "block" : "hidden") +
                " input input-bordered w-full"
              }
            />
            <img
              src={close}
              onClick={(e) => {
                data.location = "";
                setCustomLocation(false);
              }}
              className={
                (customLocation ? "block" : "hidden") +
                " absolute mr-2 w-6 cursor-pointer"
              }
              alt="Search Icon"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-4">
        <button
          onClick={previousHandler}
          type="button"
          className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
        >
          Previous
        </button>

        <button
          onClick={proceedHandler}
          type="button"
          className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
        >
          Proceed
        </button>
      </div>
    </div>
  );
};
