import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";

import spinner from "../../assets/images/spinner.gif";
import { CreateClientApi } from "../../api/ClientCreationApi";
import { MyContext } from "../dashboard/Dashboard";

const CreateClient = () => {
  const { setValue } = useContext(MyContext);
  const [data, setData] = useState({
    clientName: "",
    contactName: "",
    contactEmail: "",
    contactPhoneNum: "",
  });
  // check api names
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue("Create Client");
  });

  const submitHandler = async () => {
    // e.preventDefault();

    if (
      !data.clientName ||
      !data.contactEmail ||
      !data.contactName ||
      !data.contactPhoneNum
    ) {
      NotificationManager.warning("Please provide details", "Incomplete Data");
    } else {
      setLoading(true);
      try {
        const response = await CreateClientApi(data);
        setLoading(false);
        if (response.data) {
          setData({
            clientName: "",
            contactName: "",
            contactEmail: "",
            contactPhoneNum: "",
          });
          NotificationManager.error("Client Created", " Create Client Success");
        } else {
          NotificationManager.error(
            response.status_message,
            "Failed to Create Client"
          );
        }
      } catch (error) {
        console.log("Client Creation Failed: " + error);
        setLoading(false);
        NotificationManager.error(
          "Network error. Please check your connection",
          "Failed to Create Client"
        );
      }
    }
  };

  return (
    <>
      <div className="px-6 pt-6 2xl:container">
        <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white">
          <div className="overflow-x-auto w-full">
            <form className="grid grid-cols-1 md:grid-cols-2 flex  gap-4 md:gap-6">
              <div>
                <label
                  htmlFor="clientName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Client Name
                </label>
                <input
                  type="text"
                  name="clientName"
                  id="clientName"
                  value={data.clientName || ""}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Client Name"
                  onChange={(e) =>
                    setData(() => ({ ...data, clientName: e.target.value }))
                  }
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="contactName"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Contact Person Name
                </label>
                <input
                  type="text"
                  value={data.contactName || ""}
                  name="contactName"
                  id="contactName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Contact Person Name"
                  onChange={(e) =>
                    setData(() => ({ ...data, contactName: e.target.value }))
                  }
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="contactEmail"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Contact Person Email
                </label>
                <input
                  type="email"
                  value={data.contactEmail || ""}
                  name="contactEmail"
                  id="contactEmail"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Contact Person Email"
                  onChange={(e) =>
                    setData(() => ({ ...data, contactEmail: e.target.value }))
                  }
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="contactNumber"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Contact Person Phone Number
                </label>
                <input
                  type="text"
                  name="contactNumber"
                  value={data.contactPhoneNum || ""}
                  id="contactNumber"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Contact Person Phone Number"
                  onChange={(e) =>
                    setData(() => ({
                      ...data,
                      contactPhoneNum: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <button
                onClick={submitHandler}
                disabled={loading ? "disabled" : ""}
                className="w-1/2 mx-auto col-span-2 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 cursor-pointer cursor-pointer"
              >
                <img
                  className={(loading ? "inline" : "hidden") + " mr-2 max-h-4"}
                  src={spinner}
                  alt="loading... "
                />
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateClient;
