import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import logo from "../../assets/images/logo.png";
import spinner from "../../assets/images/spinner.gif";
import { LoginApi } from "../../api/Login";

export const Login = () => {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const loginHandler = async () => {
    if (!password || !username) {
      NotificationManager.warning(
        "Please provide credentials",
        "Incomplete Credentials"
      );
    } else {
      setLoading(true);
      // navigate("/dashboard");
      const data = { username, password };
      try {
        const response = await LoginApi(data);
        setLoading(false);
        if (response?.token) {
          const { token, user } = response
          const userData = { token, user }
          localStorage.setItem("userData", JSON.stringify(userData));
          navigate("/dashboard");
        } else {
          NotificationManager.error(
            response.status_message,
            "Failed to Login"
          );
        }
      } catch (error) {
        console.log("Login Error: " + error);
        setLoading(false);
        NotificationManager.error(
          "Network error. Please check your connection",
          "Failed to Login"
        );
      }
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mastersystems.org/"
          className="flex items-center mb-6"
        >
          <img className="h-16 mr-2" src={logo} alt="logo" />
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  // type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button
                onClick={loginHandler}
                disabled={loading ? "disabled" : ""}
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 cursor-pointer cursor-pointer"
              >
                <img className={(loading ? "inline" : "hidden") + " mr-2 max-h-4"} src={spinner} alt="loading... " />
                Sign in
              </button>
            </form>

            {/* <NotificationContainer /> */}
          </div>
        </div>
      </div>
    </section>
  );
};
