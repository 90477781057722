import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../dashboard/Dashboard";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { ClientListOnload } from "../../api/ClientListOnload";
import { Preloading } from "../../components/Preloading";
import { BlockClient } from "../../api/blockClient";

const ClientList = () => {
  const { setValue } = useContext(MyContext);
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);

  // const data = [
  //   { name: "Client1" },
  //   { name: "Client2" },
  //   { name: "Client3" },
  //   { name: "Client4" },
  // ];

  useEffect(() => {
    setValue("Clients");
    async function fetchData() {
      setLoading(true);
      try {
        const response = await ClientListOnload();
        setLoading(false);
        if (!response) {
          NotificationManager.warning(
            "Couldn't find any response",
            "Api Error"
          );
        } else {
          setClientList(response);
        }
      } catch (error) {
        setLoading(false);

        NotificationManager.error(
          "Network error. Please check your connection",
          "Failed to Collect Data"
        );
      }
    }
    fetchData();
  }, [setValue]);

  const blockHandler = async (id) => {
    const response = await BlockClient(id);
    // do change according to response
  };

  return (
    <>
      {loading && <Preloading />}
      {clientList && clientList.clients && (
        <>
          <div className="px-6 pt-6 2xl:container">
            <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white">
              <div className="flex justify-end mb-4">
                <Link to="/dashboard/create-client">
                  <button className="btn btn-sm">
                    <span className="text-xs">Create New Client</span>
                  </button>
                </Link>
              </div>
              <div className="overflow-x-auto w-full">
                <table className="table w-full">
                  {/* head */}
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Email</th>
                      <th>Phone #</th>
                      <th></th>
                    </tr>
                  </thead>
                  {(clientList) ?
                    (<tbody>
                      {clientList.clients.map((list) => (
                        <tr key={list.client_name}>
                          <td>
                            <div className="flex items-center space-x-3">
                              <div>
                                <div className="font-bold">
                                  {list.client_name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {list.address}
                            {/* <span className="badge badge-ghost badge-sm">Desktop Support Technician</span> */}
                          </td>
                          <td>{list.contact_number}</td>
                          <th>
                            <button
                              className="btn btn-ghost btn-xs"
                              onClick={() => {
                                blockHandler(list.c_id);
                              }}
                            >
                              Block
                            </button>
                          </th>
                        </tr>
                      ))}
                    </tbody>) :
                    (
                      <tbody>
                        <tr>
                          <td colSpan={4} className="text-center">No Clients Added</td>
                        </tr>
                      </tbody>
                    )
                  }
                  {/* foot */}
                  <tfoot>
                    <tr>
                      <th>Client</th>
                      <th>Email</th>
                      <th>Phone #</th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ClientList;
