import { postRequest } from "./request";

export function CreateUserApi(dataObject) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  return postRequest({
    path: `create-user`,
    token: userData.token,
    data: {
      name: dataObject.name,
      username: dataObject.userName,
      designation: dataObject.designation,
      password: dataObject.password,
      master_code: dataObject.master_code,
      department: dataObject.department,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}
