import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { UserCertificates } from "../../api/CertificateList";
import { Preloading } from "../../components/Preloading";
import { MyContext } from "../dashboard/Dashboard";
import pdfComponents from "../../pdfComponents";
const CertificatesList = () => {
  const { setValue } = useContext(MyContext);
  const [certList, setCertList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listView, setListView] = useState(true);
  const [pdfView, setPdfView] = useState(false);
  const [system, setSystem] = useState("");

  useEffect(() => {
    setValue("Certificates");
    async function fetchData() {
      setLoading(true);
      try {
        const data = await UserCertificates();
        setLoading(false);

        if (!data) {
          NotificationManager.warning(
            "Couldn't find any response",
            "Api Error"
          );
        } else {
          setCertList(data);
        }
      } catch (error) {
        setLoading(false);

        NotificationManager.error(
          "Network error. Please check your connection",
          "Failed to collect data"
        );
      }
    }
    fetchData();
  }, [setValue]);
  const pdfHandler = (value) => {
    setPdfView(true);
    setListView(false);
    const fifthPage = {
      signature1: require("../../assets/images/signature.png"),
      signature2: require("../../assets/images/signature.png"),
      logo: require("../../assets/images/pdf/header01.jpg"),
    };
    const { Client, Vessel, Location, Make, ModelName, Serial, Site, System } =
      value;
    const firstPage = {
      client: Client.client_name,
      vessel: Vessel.vessel_name,
      MMSINumber: Vessel.mmsi_number,
      IMONumber: Vessel.imo_number,
    };
    const secondPage = {
      location: Location.location_name,
      site: Site.site_name,
      jobNo: value.job_number,
      certificate: value.certificate_num,
    };
    const thirdPage = {
      make: Make.make_name,
      model: ModelName.model_name,
      serial: Serial.serial_name,
      systemCode: System.system_code,
      range: value.range_ppm,
      testDate: moment(value.test_date).format("DD-MM-YYYY"),
      dueDate: moment(value.due_date).format("DD-MM-YYYY"),
    };
    setSystem({
      sys_code: System.system_code,
      fifthPage,
      fourthPage: value.jsonObject,
      firstPage,
      secondPage,
      thirdPage,
    });
  };
  return (
    <>
      {loading && <Preloading />}
      {certList && listView && (
        <>
          <div className="px-6 pt-6 2xl:container">
            <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white">
              <div className="overflow-x-auto w-full">
                <table className="table w-full">
                  {/* head */}
                  <thead>
                    <tr>
                      <th>Certificate No</th>
                      <th>System</th>
                      <th>Client</th>
                      <th>Vessel</th>
                      <th>Location</th>
                      <th>Site</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {certList.map((list) => (
                      <tr key={list.crt_id}>
                        <td>
                          <div className="flex items-center space-x-3 font-bold">
                            {list.certificate_num}
                          </div>
                        </td>
                        <td>{list.System.system_name}</td>
                        <td>{list.Client.client_name}</td>
                        <td>{list.Vessel.vessel_name}</td>
                        <td>{list.Location.location_name}</td>
                        <td>{list.Site.site_name}</td>
                        <th>
                          <button
                            className="btn btn-success btn-xs"
                            onClick={() => {
                              pdfHandler(list);
                            }}
                          >
                            View
                          </button>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                  {/* foot */}
                  <tfoot>
                    <tr>
                      <th>Certificate No</th>
                      <th>System</th>
                      <th>Client</th>
                      <th>Vessel</th>
                      <th>Location</th>
                      <th>Site</th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
      {pdfView &&
        Object.keys(pdfComponents).map((key) => {
          const PDFComponent = pdfComponents[key];
          return (
            system.sys_code === key && (
              <div
                key={key}
                className={system.sys_code === key ? "block" : "hidden"}
              >
                <PDFComponent
                  result={system}
                  setListView={setListView}
                  setPdfView={setPdfView}
                  isCreatePage={false}
                />
              </div>
            )
          );
        })}
    </>
  );
};

export default CertificatesList;
