import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { NotificationManager } from "react-notifications";

const FireAlarmSystem = ({
  result,
  setFormView,
  setPdfView,
  apiCall,
  isCreatePage,
  setListView,
}) => {
  const componentRef = useRef();

  const [canPrint, setCanPrint] = useState(false);

  const apiCallFUnc = async () => {
    if (isCreatePage) {
      const apiCertificateCall = await apiCall();
      if (apiCertificateCall.c_id) {
        setCanPrint(true);
      } else {
        NotificationManager.warning(
          `Certificate Not Created - ${apiCertificateCall.error}`,
          "Please Try Again Later"
        );
        setCanPrint(false);
      }
    } else {
      setCanPrint(true);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => (canPrint ? componentRef.current : null),
    documentTitle: "emp-data",
    // onAfterPrint: () => alert("print success"),
    onAfterPrint: () => setCanPrint(false),
  });

  const handleClick = async () => {
    setCanPrint(false);
    await apiCallFUnc();
  };

  useEffect(() => {
    if (canPrint) {
      handlePrint();
    }
  }, [canPrint, handlePrint]);

  const { firstPage, secondPage, thirdPage, fourthPage, fifthPage } = result;

  const header = () => {
    return (
      <div className="pb-4">
        <img src={fifthPage.logo} className="w-full" alt="logo" />
      </div>
    );
  };

  const signature = () => {
    return (
      <>
        <table className="certificate w-10/12 m-auto border-collapse">
          <tbody>
            <tr>
              <td className="w-1/6 max-h-12">
                <b>Tested By</b>
              </td>
              <td className="w-2/6"></td>
              <td className="w-1/6">
                <b>Approved By</b>
              </td>
              <td className="w-2/6"></td>
            </tr>
            <tr>
              <td className="max-h-12">
                <b>Signature</b>
              </td>
              <td>
                <img
                  src={fifthPage.signature1}
                  className="max-h-10 m-2"
                  alt="sign"
                />
              </td>
              <td>
                <b>Signature</b>{" "}
              </td>
              <td>
                <img
                  src={fifthPage.signature2}
                  className="max-h-10 m-2"
                  alt="sign"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br className="br" />
      </>
    );
  };

  const jobDetails = () => {
    return (
      <table className="certificate w-10/12 m-auto border-collapse">
        <tbody>
          <tr>
            <td colSpan={2} className="w-1/5 bg-slate-200">
              <b>Job No</b>
            </td>
            <td colSpan={2} className="w-30">{secondPage.jobNo}</td>
            <td colSpan={3} className="w-1/5 bg-slate-200">
              <b>Test Date</b>
            </td>
            <td colSpan={2}>{thirdPage.testDate}</td>
          </tr>
          <tr>
            <td colSpan={2} className="bg-slate-200">
              <b>Certificate</b>
            </td>
            <td colSpan={2}>{secondPage.certificate}</td>
            <td colSpan={3} className="bg-slate-200">
              <b>Test Due</b>
            </td>
            <td colSpan={2}>{thirdPage.dueDate}</td>
          </tr>
          <tr>
            <td colSpan={2} className="bg-slate-200">
              <b>Procedure</b>
            </td>
            <td colSpan={2}>{thirdPage.systemCode}</td>
            <td colSpan={3} className="bg-slate-200">
              <b>Location</b>
            </td>
            <td colSpan={2}>{secondPage.location}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const test = () => {
    return (
      <table className="certificate w-10/12 m-auto border-collapse text-center">
        <tbody>
          <tr>
            <td colSpan={9} className="bg-slate-300">
              <b>TEST DATA</b>
            </td>
          </tr>
          <tr className="bg-slate-100">
            <td colSpan={3}>
              <b>ZONE</b>
            </td>
            <td colSpan={2}>
              <b>SMOKE DETECTOR</b>
            </td>
            <td colSpan={2}>
              <b>HEAT DETECTOR</b>
            </td>
            <td colSpan={2}>
              <b>MANUAL CALL POINT</b>
            </td>
          </tr>
          {fourthPage.testData.map((list) => (
            <tr key={list.ZONE}>
              <td colSpan={3} className="text-left">
                {list.ZONE}
              </td>
              <td colSpan={2}>{list.SmokeDetector}</td>
              <td colSpan={2}>{list.HeatDetector}</td>
              <td colSpan={2}>{list.ManualCallPoint}</td>
            </tr>
          ))}
          <tr>
            <td colSpan={3}>
              <br />
            </td>
            <td colSpan={2}></td>
            <td colSpan={2}></td>
            <td colSpan={2}></td>
          </tr>
          <tr>
            <td colSpan={3} className="text-left"></td>
            <td colSpan={2}>{fourthPage.smokeSum}</td>
            <td colSpan={2}>{fourthPage.heatSum}</td>
            <td colSpan={2}>{fourthPage.callPointSum}</td>
          </tr>
          <tr>
            <td colSpan={3} rowSpan={2}>
              Power Supply:
            </td>
            <td colSpan={2}>Main Power (230 V AC)</td>
            <td colSpan={2}>Backup Power (24v DC)</td>
            <td colSpan={2}>Backup Battery</td>
          </tr>
          <tr>
            <td colSpan={2}>{fourthPage.permission230V}</td>
            <td colSpan={2}>{fourthPage.permission24v}</td>
            <td colSpan={2}>{fourthPage.permissionBattery}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const footer = (pgnumber) => {
    return (
      <>
        <div className="w-10/12 m-auto space-y-2 text-xs pb-4">
          <div>
            <div className="flex justify-between">
              <p>Ref No: MS-QAT-QHSEMSP 013.04</p>
              <p>Rev No: 00</p>
              <p>Issue No: 01</p>
              <p>Effective Date: {thirdPage.testDate}</p>
            </div>
            <p className="text-center">page {pgnumber}/2</p>
          </div>
          <div>
            <hr className="line" />
          </div>
          <div>
            <p className="text-center">
              Tel: +97149919370 | Fax: +97167411427 | Dubai,UAE | e-mail:
              lab.dubai@mastersystems.com
            </p>
            <p className="text-center">web: www.mastersystems.com</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {result && firstPage && secondPage && thirdPage && fourthPage && (
        <div>
          <div className="border border-slate-300">
            <div ref={componentRef} className="w-full bg-white">
              <div className="flex flex-col justify-between min-h-screen">
                {header()}
                <table className="certificate w-10/12 m-auto border-collapse">
                  <thead className="mb-4">
                    <tr>
                      <th colSpan={9} className="bg-slate-400">
                        <span className="text-2xl font-bold">
                          TEST CERTIFICATE
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={9} className="bg-slate-200">
                        <span className="text-base font-bold">
                          Fire Alarm System
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={9} className="border-none">
                        <br className="br" />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="w-1/5 bg-slate-200">
                        <b>Client</b>
                      </td>
                      <td colSpan={2} className="w-30">
                        <b>{firstPage.client}</b>
                      </td>
                      <td colSpan={3} className="w-1/5 bg-slate-200">
                        <b>Vessel</b>
                      </td>
                      <td colSpan={2}>
                        <b>{firstPage.vessel}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="bg-slate-200">
                        <b>MMSI Number</b>
                      </td>
                      <td colSpan={2}>
                        <b>{firstPage.MMSINumber}</b>
                      </td>
                      <td colSpan={3} className="bg-slate-200">
                        <b>IMO Number</b>
                      </td>
                      <td colSpan={2}>
                        <b>{firstPage.IMONumber}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br className="br" />
                {jobDetails()}
                <br className="br" />

                <table className="certificate w-10/12 m-auto border-collapse">
                  <tbody>
                    <tr>
                      <td colSpan={9} className="text-center bg-slate-300">
                        <b>UNIT UNDER TEST SPECIFICATIONS</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="bg-slate-200">
                        <b>Description</b>
                      </td>
                      <td colSpan={7}>Fire Alarm System</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="bg-slate-200">
                        <b>Make</b>
                      </td>
                      <td colSpan={2}>{thirdPage.make}</td>
                      <td colSpan={3} className="bg-slate-200">
                        <b>Model</b>
                      </td>
                      <td colSpan={2}>{thirdPage.model}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="bg-slate-200">
                        <b>Serial No</b>
                      </td>
                      <td colSpan={2}>{thirdPage.serial}</td>
                      <td colSpan={3} className="bg-slate-200">
                        <b>Type</b>
                      </td>
                      <td colSpan={2}>{thirdPage.range}</td>
                    </tr>
                    <tr>
                      <td colSpan={9} className="border-none">
                        <br className="br" />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={9} className="text-center bg-slate-300">
                        <b>ENVIRONMENTAL CONDITION</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>Temperature</b>
                      </td>
                      <td colSpan={2}>{fourthPage.temperature}</td>
                      <td colSpan={2}>
                        <b>Relative Humidity</b>
                      </td>
                      <td colSpan={2}>{fourthPage.relativeHumidity}</td>
                    </tr>
                  </tbody>
                </table>

                <br className="br" />

                <table className="certificate w-10/12 m-auto border-collapse text-center">
                  <tbody>
                    <tr>
                      <td colSpan={5} className="bg-slate-300">
                        <b>TRACEABILITY OF MASTER EQUIPMENT</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-1/4 text-left">
                        <b>Reference Instrument</b>
                      </td>
                      <td className="w-1/4">
                        <b>Make</b>
                      </td>
                      <td className="w-1/6">
                        <b>Type</b>
                      </td>
                      <td className="w-1/6">
                        <b>Serial No.</b>
                      </td>
                      <td className="w-1/6">
                        <b>LOT.Expiry</b>
                      </td>
                    </tr>

                    {fourthPage.testEquipment.map((list) => (
                      <tr key={list.referenceInstrument}>
                        <td className="text-left">
                          {list.referenceInstrument}
                        </td>
                        <td>{list.make}</td>
                        <td>{list.type}</td>
                        <td>{list.serial}</td>
                        <td>{list.LotExpiry}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br className="br" />
                <div className="w-10/12 m-auto">
                  <p className="text-xs">
                    The certificate has been issued in accordance with Standard
                    Laboratory Developed Approved Procedures and/or National &
                    International Standards.The above Calibration was based on
                    BS EN 45544-1:2015. Master System wll not be responsible for
                    any malfunction due to negligence improper use, or
                    breakdown.The System's operation and alarms were checked and
                    verified as functional. The detector were adjusted to
                    indicate 0ppm in the fresh air.This was re-confirmed after
                    the test,on purging with fresh air. All Larms were checked
                    and verified as functional.
                  </p>
                </div>
                <br className="br" />

                <table className="certificate w-10/12 m-auto border-collapse">
                  <tbody>
                    <tr>
                      <td>Remarks: Nil</td>
                    </tr>
                  </tbody>
                </table>
                <br className="br" />

                {3 > 2 ? (
                  <>
                    <br className="br" />
                    {signature()}
                    <div className="grow">
                      <br className="br" />
                    </div>
                    {footer(1)}
                  </>
                ) : (
                  <>
                    {test()}
                    <br className="br" />
                    {signature()}
                    <div className="grow">
                      <br className="br" />
                    </div>
                    {footer(1)}
                  </>
                )}
              </div>
              {3 > 2 ? (
                <>
                  <div className="page-break" />
                  <div className="flex flex-col justify-end min-h-screen">
                    <div>
                      {header()}
                      <br className="br" />
                      {jobDetails()}
                      <br className="br" />
                      {test()}
                      <br className="br" />
                      <br className="br" />
                      {signature()}
                    </div>

                    <div className="grow">
                      <br className="br" />
                    </div>
                    {footer(2)}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="flex justify-center gap-4 m-6 p-6">
            {isCreatePage ? (
              <button
                onClick={() => {
                  setFormView(true);
                  setPdfView(false);
                }}
                type="button"
                className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
              >
                Previous Page
              </button>
            ) : (
              <button
                onClick={() => {
                  setListView(true);
                  setPdfView(false);
                }}
                type="button"
                className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
              >
                Close
              </button>
            )}
            <button
              onClick={handleClick}
              type="button"
              className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              {isCreatePage ? "  Print PDF & Save" : "Print PDF"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default FireAlarmSystem;
