import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import close from "../assets/images/close.png";
import MultiGasDetectionPDF from "../pdf/MultiGas_Detection";

export const MultigasDetection = ({
  setResult,
  result,
  setShow1,
  setShow2,
  setShow3,
  setShowForm,
  apiCall,
}) => {
  const [formView, setFormView] = useState(true);
  const [pdfView, setPdfView] = useState(false);

  const [data, setData] = useState("");

  const [serviceList, setServiceList] = useState([{ concentration: [{}] }]);
  const [testData, setTestData] = useState([{}]);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleServiceDelete = (e, index) => {
    const newServiceList = serviceList.filter((v, i) => i !== index);
    setServiceList(newServiceList);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { concentration: [{}] }]);
  };

  const handleTestDataChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...testData];
    list[index][name] = value;
    setTestData(list);
  };

  const handleTestDataDelete = (e, index) => {
    const newtestData = testData.filter((v, i) => i !== index);
    setTestData(newtestData);
  };

  const handleTestDataAdd = () => {
    setTestData([...testData, {}]);
  };

  const handleConcentrationLoopChange = (e, index, ele) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index]["concentration"][ele][name] = value;
    setServiceList(list);
  };

  const handleConcentrationLoopDelete = (index, ele) => {
    const list = [...serviceList];
    list[index]["concentration"].splice(ele--, 1);
    setServiceList(list);
  };

  const handleConcentrationLoopAdd = (ele) => {
    const list = [...serviceList];
    list[ele]["concentration"].push({});
    setServiceList(list);
  };

  const previousHandler = () => {
    setResult(() => ({
      ...result,
      fourthPage: { ...data, serviceList, testData },
    }));
    setShow1(false);
    setShow2(false);
    setShow3(true);
    setShowForm(false);
  };
  const submitHandler = () => {
    const serviceError = serviceList
      .map((list) => {
        if (
          list.referenceInstrument &&
          list.make &&
          list.LOTNO &&
          list.LotExpiry
        ) {
          return true;
        } else {
          return false;
        }
      })
      .every((val) => val === true);

    const testDataError = testData
      .map((list) => {
        if (
          list.Gas &&
          list.Units &&
          list.concentration &&
          list.freshAir &&
          list.spanGas &&
          list.error &&
          list.Alarm1 &&
          list.Alarm2
        ) {
          return true;
        } else {
          return false;
        }
      })
      .every((val) => val === true);

    if (
      data !== "" &&
      data.temperature !== undefined &&
      data.relativeHumidity !== undefined &&
      data.temperature !== "" &&
      data.relativeHumidity !== "" &&
      serviceError &&
      testDataError
    ) {
      setFormView(false);
      setPdfView(true);
      setResult(() => ({
        ...result,
        fourthPage: {
          ...data,
          serviceList,
          testData,
        },
      }));
    } else {
      NotificationManager.warning(
        "Please complete the form",
        "Incomplete Form"
      );
    }
  };
  return (
    <>
      <div className={formView ? "block" : "hidden"}>
        <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white space-y-10">
          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">
              ENVIRONMENTAL CONDITION
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Temperature</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setData(() => ({ ...data, temperature: e.target.value }));
                  }}
                  placeholder="Enter Temperature"
                  className="input input-bordered w-full"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Relative Humidity</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setData(() => ({
                      ...data,
                      relativeHumidity: e.target.value,
                    }));
                  }}
                  placeholder="Enter Relative Humidity"
                  className="input input-bordered w-full"
                />
              </div>
            </div>
          </div>

          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">
              TRACEABILITY OF MASTER EQUIPMENT
            </h2>
            <div>
              {serviceList.map((singleService, index) => (
                <div
                  key={"equipment-" + index}
                  className="flex justify-between"
                >
                  <div className="grid grid-cols-2 md:grid-cols-5 gap-6">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Reference Instrument</span>
                      </label>
                      <input
                        value={singleService.referenceInstrument || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="referenceInstrument"
                        placeholder="Enter Reference Instrument"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Make</span>
                      </label>
                      <input
                        value={singleService.make || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="make"
                        placeholder="Enter Make"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div>
                      {singleService.concentration.map((loop, ele) => (
                        <div
                          key={index + "concentration" + ele}
                          className="form-control w-full"
                        >
                          <label className="label">
                            <span className="label-text">Concentration</span>
                          </label>
                          <div className="flex">
                            <input
                              value={loop.conName || ""}
                              type="text"
                              onChange={(e) =>
                                handleConcentrationLoopChange(e, index, ele)
                              }
                              name="conName"
                              placeholder="Enter Concentration"
                              className="input input-bordered w-full"
                            />
                            {ele === 0 ? null : (
                              <div className="flex flex-wrap content-center">
                                <img
                                  src={close}
                                  onClick={() => {
                                    handleConcentrationLoopDelete(index, ele);
                                  }}
                                  className="h-6 w-6 cursor-pointer"
                                  alt="Search Icon"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-end mb-2 gap-2">
                        <button
                          type="button"
                          onClick={() => handleConcentrationLoopAdd(index)}
                          className="inline-block mt-4 px-8 py-2 bg-blue-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
                        >
                          <span>Add Concentration</span>
                        </button>
                      </div>
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">LOT. No</span>
                      </label>
                      <input
                        value={singleService.LOTNO || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="LOTNO"
                        placeholder="Enter LOT No ."
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">LotExpiry</span>
                      </label>
                      <input
                        value={singleService.LotExpiry || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="LotExpiry"
                        placeholder="Enter LotExpiry"
                        className="input input-bordered w-full"
                      />
                    </div>
                  </div>
                  {index === 0 ? null : (
                    <div className="flex flex-wrap content-center">
                      <img
                        src={close}
                        onClick={(e) => {
                          handleServiceDelete(e, index);
                        }}
                        className="h-6 w-6 cursor-pointer"
                        alt="Search Icon"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-end mb-2 gap-2">
              <button
                type="button"
                onClick={handleServiceAdd}
                className="inline-block mt-4 px-8 py-2 bg-green-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
              >
                <span>Add Reference Equipment</span>
              </button>
            </div>
          </div>
          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">TEST DATA </h2>
            <div>
              {testData.map((tdata, index) => (
                <div
                  key={"equipment-" + index}
                  className="flex justify-between"
                >
                  <div className="grid grid-cols-2 md:grid-cols-8 gap-6">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Gas</span>
                      </label>
                      <input
                        value={tdata.Gas || ""}
                        type="text"
                        onChange={(e) => handleTestDataChange(e, index)}
                        name="Gas"
                        placeholder="Enter Gas"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Units</span>
                      </label>
                      <input
                        value={tdata.Units || ""}
                        type="text"
                        onChange={(e) => handleTestDataChange(e, index)}
                        name="Units"
                        placeholder="Enter Units"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">GAS/Concentration</span>
                      </label>
                      <input
                        value={tdata.concentration || ""}
                        type="text"
                        onChange={(e) => handleTestDataChange(e, index)}
                        name="concentration"
                        placeholder="Enter Concentration Value"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Fresh Air</span>
                      </label>
                      <input
                        value={tdata.freshAir || ""}
                        type="text"
                        onChange={(e) => handleTestDataChange(e, index)}
                        name="freshAir"
                        placeholder="Enter Fresh Air Value"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Span Gas</span>
                      </label>
                      <input
                        value={tdata.spanGas || ""}
                        type="text"
                        onChange={(e) => handleTestDataChange(e, index)}
                        name="spanGas"
                        placeholder="Enter Span Gas Value"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Error</span>
                      </label>
                      <input
                        value={tdata.error || ""}
                        type="text"
                        onChange={(e) => handleTestDataChange(e, index)}
                        name="error"
                        placeholder="Enter Error Value"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Alarm 1</span>
                      </label>
                      <input
                        value={tdata.Alarm1 || ""}
                        type="text"
                        onChange={(e) => handleTestDataChange(e, index)}
                        name="Alarm1"
                        placeholder="Enter Alarm 1 Value"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Alarm 2</span>
                      </label>
                      <input
                        value={tdata.Alarm2 || ""}
                        type="text"
                        onChange={(e) => handleTestDataChange(e, index)}
                        name="Alarm2"
                        placeholder="Enter Alarm 2 Value"
                        className="input input-bordered w-full"
                      />
                    </div>
                  </div>
                  {index === 0 ? null : (
                    <div className="flex flex-wrap content-center">
                      <img
                        src={close}
                        onClick={(e) => {
                          handleTestDataDelete(e, index);
                        }}
                        className="h-6 w-6 cursor-pointer"
                        alt="Search Icon"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-end mb-2 gap-2">
              <button
                type="button"
                onClick={handleTestDataAdd}
                className="inline-block mt-4 px-8 py-2 bg-green-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
              >
                <span>Add Gas</span>
              </button>
            </div>
          </div>

          <div className="flex justify-end gap-4">
            <button
              onClick={previousHandler}
              type="button"
              className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Previous
            </button>

            <button
              onClick={submitHandler}
              type="button"
              className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {pdfView ? (
        <div>
          <MultiGasDetectionPDF
            result={result}
            setFormView={setFormView}
            setPdfView={setPdfView}
            apiCall={apiCall}
            isCreatePage={true}
          />
        </div>
      ) : null}
    </>
  );
};
