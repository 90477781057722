import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import close from "../assets/images/close.png";

export const FormPageThree = ({
  setResult,
  systemAndSpecification,
  result,
  setShow1,
  setShow2,
  setShow3,
  setShowForm,
  setFormNumber,
}) => {
  const [data, setData] = useState({
    make: "",
    model: "",
    serial: "",
    testDate: "",
    systemCode: "",
    dueDate: "",
  });
  const [dueDate, setDueDate] = useState(false);
  const [inputForm, setInputForm] = useState(false);
  const [systems, setSystems] = useState([]);
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [serial, setSerial] = useState([]);
  const [modelFlag, setModelFlag] = useState(false);
  const [serialFlag, setSerialFlag] = useState(false);

  useEffect(() => {
    var now = new Date();
    var next_month = new Date(now.setMonth(now.getMonth()));
    var day = ("0" + next_month.getDate()).slice(-2);
    var month = ("0" + (next_month.getMonth() + 1)).slice(-2);
    var next_month_string = day + "-" + month + "-" + next_month.getFullYear();
    setData(() => ({ ...data, testDate: next_month_string }));
    if (systemAndSpecification && systemAndSpecification.systems) {
      setSystems(systemAndSpecification.systems);
    }
    if (systemAndSpecification && systemAndSpecification.makes) {
      setMake(systemAndSpecification.makes);
    }
  }, [systemAndSpecification]);

  const getModelSort = (make) => {
    let filteredArray = systemAndSpecification.models.filter(
      (obj) => obj.make_id === Number(make)
    );
    let uniqueModel = Array.from(
      new Set(filteredArray.map((obj) => obj.model_id))
    )
      .map((model) => {
        return filteredArray.find((obj) => obj.model_id === model);
      })
      .sort((a, b) => {
        if (a.model > b.model) {
          return 1;
        } else if (a.model < b.model) {
          return -1;
        } else {
          return 0;
        }
      });

    setModel(uniqueModel);
  };

  const getSerialSort = (model) => {
    let filteredArray = systemAndSpecification.serials.filter(
      (obj) => obj.model_id === Number(model)
    );
    let uniqueSerial = filteredArray.sort((a, b) => {
      if (a.id > b.id) {
        return 1;
      } else if (a.id < b.id) {
        return -1;
      } else {
        return 0;
      }
    });
    setSerial(uniqueSerial);
  };

  const previousHandler = () => {
    setResult(() => ({ ...result, thirdPage: { ...data } }));
    setShow1(false);
    setShow2(true);
    setShow3(false);
    setShowForm(false);
  };

  const proceedHandler = () => {
    setResult(() => ({
      ...result,
      thirdPage: { ...data },
      fifthPage: {
        signature1: require("../assets/images/signature.png"),
        signature2: require("../assets/images/signature.png"),
        logo: require("../assets/images/pdf/header01.jpg"),
      },
    }));
    if (
      !!data &&
      !!data.system &&
      !!data.dueDate &&
      !!data.make &&
      !!data.model &&
      !!data.serial &&
      !!data.range
    ) {
      setShow1(false);
      setShow2(false);
      setShow3(false);
      setShowForm(true);
    } else {
      NotificationManager.warning(
        "Please complete the form",
        "Incomplete Form"
      );
    }
  };

  return (
    <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white space-y-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Select the System</span>
          </label>
          <select
            onChange={(e) => {
              if (
                data.system !== undefined &&
                Number(e.target.value) !== Number(data.system)
              ) {
                // clear data in fourth form
                setResult(() => ({ ...result, fourthPage: {} }));
              }

              let sys_code = "";
              for (var i = 0; i < systems.length; i++) {
                if (systems[i].sys_id === Number(e.target.value)) {
                  sys_code = systems[i].system_code;
                }
              }
              setFormNumber(sys_code);
              // system code from api
              setData(() => ({
                ...data,
                system: e.target.value,
                systemCode: sys_code,
              }));
            }}
            value={
              data !== undefined
                ? data.system !== undefined
                  ? data.system
                  : ""
                : ""
            }
            className="select select-bordered"
          >
            <option disabled value="">
              Pick the System
            </option>
            {!systems.length ? (
              <option value="0" disabled>
                Please sync and try again
              </option>
            ) : (
              systems.map((option) => (
                <option key={option.sys_id} value={option.sys_id}>
                  {option.system_name}
                </option>
              ))
            )}
          </select>
        </div>

        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">System Code</span>
          </label>
          <input
            type="text"
            value={data.systemCode}
            disabled
            className="input input-bordered w-full !border-gray-300"
          />
        </div>

        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Test Date (dd-mm-yyyy)</span>
          </label>
          <input
            type="text"
            defaultValue={
              data !== undefined
                ? data.testDate !== undefined || data.testDate !== null
                  ? data.testDate
                  : ""
                : ""
            }
            onChange={(e) => {
              setData(() => ({ ...data, testDate: e.target.value }));
            }}
            className="input input-bordered w-full !border-gray-300"
          />
        </div>

        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Select the Due Date (dd-mm-yyyy)</span>
          </label>
          <select
            onChange={(e) => {
              setDueDate(true);
              if (e.target.value !== "customOption") {
                var now = new Date();
                var next_month = new Date(
                  now.setMonth(now.getMonth() + Number(e.target.value))
                );
                var day = ("0" + next_month.getDate()).slice(-2);
                var month = ("0" + (next_month.getMonth() + 1)).slice(-2);
                var next_month_string =
                  day + "-" + month + "-" + next_month.getFullYear();

                setData(() => ({
                  ...data,
                  dueDate: next_month_string,
                }));
              } else {
                setData(() => ({
                  ...data,
                  dueDate: e.target.value,
                }));
              }
            }}
            defaultValue={
              data !== undefined
                ? data.dueDate !== undefined
                  ? data.dueDate
                  : ""
                : ""
            }
            className={
              (dueDate ? "hidden" : "block") + " select select-bordered"
            }
          >
            <option>Pick the Due Date</option>
            <option value="1">One Month</option>
            <option value="2">Two Months</option>
            <option value="3">Three Months</option>
            <option value="6">Six Months</option>
            <option value="12">One Year</option>
            <option value="customOption">Custom Due Date</option>
          </select>

          <div className="w-full flex justify-end items-center relative">
            <input
              placeholder="Enter Due Date"
              onChange={(e) => {
                setData(() => ({ ...data, dueDate: e.target.value }));
              }}
              defaultValue={
                data !== undefined
                  ? data.dueDate !== undefined || data.dueDate !== null
                    ? data.dueDate
                    : ""
                  : ""
              }
              className={
                (dueDate ? "block" : "hidden") + " input input-bordered w-full"
              }
            />
            <img
              src={close}
              onClick={(e) => {
                setDueDate(false);
                let temp = data;
                temp.dueDate = null;
                setData(temp);
              }}
              className={
                (dueDate ? "block" : "hidden") +
                " absolute mr-2 w-6 cursor-pointer"
              }
              alt="Search Icon"
            />
          </div>
        </div>
      </div>

      <div>
        <h2 className="mt-2 text-lg font-bold text-gray-600">
          UNIT UNDER TEST SPECIFICATIONS
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Select the Make</span>
            </label>
            <select
              onChange={(e) => {
                setData(() => ({ ...data, make: "" }));
                if (e.target.value === "customOption") {
                  setInputForm(true);
                  setModelFlag(true);
                  setSerialFlag(true);
                } else {
                  let make_id = "";
                  for (var i = 0; i < make.length; i++) {
                    if (make[i].make_name === e.target.value) {
                      make_id = make[i].make_id;
                    }
                  }
                  setData(() => ({
                    ...data,
                    make: e.target.value,
                    make_id: make_id,
                  }));
                  getModelSort(make_id);
                }
              }}
              value={
                data !== undefined
                  ? data.make !== undefined || data.make !== null
                    ? data.make
                    : ""
                  : ""
              }
              className={
                (inputForm ? "hidden" : "block") + " select select-bordered"
              }
            >
              <option value="" disabled>
                Pick the Make
              </option>
              {!make.length ? (
                <></>
              ) : (
                make.map((option) => (
                  <option key={option.make_id} value={option.make_name}>
                    {option.make_name}
                  </option>
                ))
              )}
              <option value="customOption">New Make</option>
            </select>

            <div className="w-full flex justify-end items-center relative">
              <input
                placeholder="Enter Make"
                onChange={(e) => {
                  setData(() => ({
                    ...data,
                    make: e.target.value,
                    make_id: "",
                  }));
                }}
                value={
                  data !== undefined
                    ? data.make !== undefined || data.make !== null
                      ? data.make
                      : ""
                    : ""
                }
                className={
                  (inputForm ? "block" : "hidden") +
                  " input input-bordered w-full"
                }
              />
              <img
                src={close}
                onClick={(e) => {
                  setData(() => ({ ...data, make: "" }));
                  setInputForm(false);
                  setModelFlag(false);
                  setSerialFlag(false);
                }}
                className={
                  (inputForm ? "block" : "hidden") +
                  " absolute mr-2 w-6 cursor-pointer"
                }
                alt="Search Icon"
              />
            </div>
          </div>

          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Select the Model</span>
            </label>
            <select
              onChange={(e) => {
                setData(() => ({ ...data, model: "" }));
                if (e.target.value === "customOption") {
                  setModelFlag(true);
                  setSerialFlag(true);
                } else {
                  let model_id = "";
                  for (var i = 0; i < model.length; i++) {
                    if (model[i].model_name === e.target.value) {
                      model_id = model[i].model_id;
                    }
                  }
                  setData(() => ({
                    ...data,
                    model: e.target.value,
                    model_id: model_id,
                  }));
                  getSerialSort(model_id);
                }
              }}
              value={
                data !== undefined
                  ? data.model !== undefined || data.model !== null
                    ? data.model
                    : ""
                  : ""
              }
              className={
                (modelFlag ? "hidden" : "block") + " select select-bordered"
              }
            >
              <option value="" disabled>
                Pick the Model
              </option>
              {!model.length ? (
                <></>
              ) : (
                model.map((option) => (
                  <option key={option.model_id} value={option.model_name}>
                    {option.model_name}
                  </option>
                ))
              )}
              <option value="customOption">New Model</option>
            </select>

            <div className="w-full flex justify-end items-center relative">
              <input
                placeholder="Enter Model"
                onChange={(e) => {
                  setData(() => ({
                    ...data,
                    model: e.target.value,
                    model_id: "",
                  }));
                }}
                value={
                  data !== undefined
                    ? data.model !== undefined || data.model !== null
                      ? data.model
                      : ""
                    : ""
                }
                className={
                  (modelFlag ? "block" : "hidden") +
                  " input input-bordered w-full"
                }
              />
              <img
                src={close}
                onClick={(e) => {
                  setData(() => ({ ...data, model: "" }));
                  setModelFlag(false);
                  setSerialFlag(false);
                }}
                className={
                  (modelFlag ? "block" : "hidden") +
                  " absolute mr-2 w-6 cursor-pointer"
                }
                alt="Search Icon"
              />
            </div>
          </div>

          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Select the Serial No.</span>
            </label>
            <select
              onChange={(e) => {
                setData(() => ({ ...data, serial: "" }));
                if (e.target.value === "customOption") {
                  setSerialFlag(true);
                } else {
                  let serial_id = "";
                  for (var i = 0; i < serial.length; i++) {
                    if (serial[i].serial_name === e.target.value) {
                      serial_id = serial[i].serial_id;
                    }
                  }
                  setData(() => ({
                    ...data,
                    serial: e.target.value,
                    serial_id: serial_id,
                  }));
                }
              }}
              value={
                data !== undefined
                  ? data.serial !== undefined || data.serial !== null
                    ? data.serial
                    : ""
                  : ""
              }
              className={
                (serialFlag ? "hidden" : "block") + " select select-bordered"
              }
            >
              <option value="" disabled>
                Pick the Serial No.
              </option>
              {!serial.length ? (
                <></>
              ) : (
                serial.map((option) => (
                  <option key={option.serial_id} value={option.serial_name}>
                    {option.serial_name}
                  </option>
                ))
              )}
              <option value="customOption">New Serial No.</option>
            </select>

            <div className="w-full flex justify-end items-center relative">
              <input
                placeholder="Enter Serial No"
                onChange={(e) => {
                  setData(() => ({
                    ...data,
                    serial: e.target.value,
                    serial_id: "",
                  }));
                }}
                value={
                  data !== undefined
                    ? data.serial !== undefined || data.serial !== null
                      ? data.serial
                      : ""
                    : ""
                }
                className={
                  (serialFlag ? "block" : "hidden") +
                  " input input-bordered w-full"
                }
              />
              <img
                src={close}
                onClick={(e) => {
                  setData(() => ({ ...data, serial: "" }));
                  setSerialFlag(false);
                }}
                className={
                  (serialFlag ? "block" : "hidden") +
                  " absolute mr-2 w-6 cursor-pointer"
                }
                alt="Search Icon"
              />
            </div>
          </div>

          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Range</span>
            </label>
            <input
              type="text"
              onChange={(e) => {
                setData(() => ({ ...data, range: e.target.value }));
              }}
              value={data.range}
              placeholder="Enter Range"
              className="input input-bordered w-full"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-4">
        <button
          onClick={previousHandler}
          type="button"
          className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
        >
          Previous
        </button>

        <button
          onClick={proceedHandler}
          type="button"
          className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
        >
          Proceed
        </button>
      </div>
    </div>
  );
};
