import React, { useContext, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { MyContext } from "../dashboard/Dashboard";
import { CreateVesselApi } from "../../api/VesselCreationApi";

import spinner from "../../assets/images/spinner.gif";
import { LoadVessel } from "../../api/loadVessel";

const CreateVessel = () => {
  const { setValue } = useContext(MyContext);
  const [data, setData] = useState({
    vesselName: "",
    client: "",
    IMONumber: "",
    MMSI: "",
  });
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    setValue("Create Vessel");
  });

  useEffect(() => {
    async function fetchData() {
      const response = await LoadVessel();
      if (response.clients) {
        setClients(response.clients);
      } else {
        NotificationManager.error(
          "Clients Fetch Failed",
          "Failed to Fetch Clients"
        );
      }
    }
    fetchData();
  }, []);

  const submitHandler = async () => {
    // e.preventDefault();

    if (!data.vesselName || !data.IMONumber || !data.client || !data.MMSI) {
      NotificationManager.warning("Please provide details", "Incomplete Data");
    } else {
      setLoading(true);
      try {
        const response = await CreateVesselApi(data);
        setLoading(false);
        if (response.v_id) {
          // if response do something
          setData({ vesselName: "", client: "", IMONumber: "", MMSI: "" });
          NotificationManager.error(
            response.status_message,
            "Create Vessel Succed"
          );
        } else {
          NotificationManager.error(
            response.status_message,
            "Failed to Create Vessel"
          );
        }
      } catch (error) {
        console.log("Vessel Creation Failed: " + error);
        setLoading(false);
        NotificationManager.error(
          "Network error. Please check your connection",
          "Failed to Create Vessel"
        );
      }
    }
  };

  return (
    <>
      {clients && (
        <div className="px-6 pt-6 2xl:container">
          <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white">
            <div className="overflow-x-auto w-full">
              <form className="grid grid-cols-1 md:grid-cols-2 flex  gap-4 md:gap-6">
                <div>
                  <label
                    htmlFor="vesselName"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Vessel Name
                  </label>
                  <input
                    type="text"
                    name="vesselName"
                    value={data.vesselName || ""}
                    id="vesselName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Vessel Name"
                    onChange={(e) =>
                      setData(() => ({ ...data, vesselName: e.target.value }))
                    }
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="client"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select Client
                  </label>
                  <select
                    required
                    onChange={(e) => {
                      setData(() => ({
                        ...data,
                        client: e.target.value,
                      }));
                    }}
                    value={data.client ? data.client : ""}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option disabled value="">
                      Pick the System
                    </option>
                    {/* make it dynamic from api */}
                    {clients.map((client, index) => (
                      <option key={index} value={client.c_id}>
                        {client.client_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="IMONumber"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    IMO Number
                  </label>
                  <input
                    type="text"
                    value={data.IMONumber || ""}
                    name="IMONumber"
                    id="IMONumber"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter IMO Number"
                    onChange={(e) =>
                      setData(() => ({ ...data, IMONumber: e.target.value }))
                    }
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="MMSI"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    MMSI Number
                  </label>
                  <input
                    type="text"
                    name="MMSI"
                    value={data.MMSI || ""}
                    id="MMSI"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter MMSI Number"
                    onChange={(e) =>
                      setData(() => ({
                        ...data,
                        MMSI: e.target.value,
                      }))
                    }
                    required
                  />
                </div>
                <button
                  onClick={submitHandler}
                  disabled={loading ? "disabled" : ""}
                  className="w-1/2 mx-auto col-span-2 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 cursor-pointer cursor-pointer"
                >
                  <img
                    className={
                      (loading ? "inline" : "hidden") + " mr-2 max-h-4"
                    }
                    src={spinner}
                    alt="loading... "
                  />
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CreateVessel;
