import { postRequest } from "./request";

export function LoginApi(dataObject) {
  return postRequest({
    path: `login`,
    data: {
      username: dataObject.username,
      password: dataObject.password,
    },
  }).then((response) => {
    return response ? response : undefined;
  });
}
