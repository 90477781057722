import AlcoholDetectionPDF from "./pdf/AlcoholDetection";
import PpmMonitor from "./pdf/ppm_monitor";
import FireAlarmSystem from "./pdf/FireAlarmSystem";
import FixedGasDetectionPDF from "./pdf/FixedGas_Detection";
import PortableGasDetectorPDF from "./pdf/PortableGasDetector";
import MultiGasDetectionPDF from "./pdf/MultiGas_Detection";
import AmmeterPDF from "./pdf/Ammeter";
import AnemometerPDF from "./pdf/Anemometer";
import BarometerPDF from "./pdf/Barometer";

const pdfComponents = {
  fifteen_ppm_monitor: PpmMonitor,
  fire_alarm_system: FireAlarmSystem,
  portable_gas_detector: PortableGasDetectorPDF,
  alcohol_detection: AlcoholDetectionPDF,
  fixed_gas_detection: FixedGasDetectionPDF,
  multi_gas_detection: MultiGasDetectionPDF,
  ammeter: AmmeterPDF,
  anemometer: AnemometerPDF,
  barometer: BarometerPDF,
};
export default pdfComponents;
