import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import close from "../assets/images/close.png";
import AlcoholDetectionPDF from "../pdf/AlcoholDetection";

export const AlcoholDetection = ({
  setResult,
  result,
  setShow1,
  setShow2,
  setShow3,
  setShowForm,
  apiCall,
}) => {
  const [formView, setFormView] = useState(true);
  const [pdfView, setPdfView] = useState(false);

  const [data, setData] = useState("");

  const [testData, setTestData] = useState([{}]);
  const [testEquipment, setTestEquipment] = useState([
    { referenceInstrument: "Standard Calibration Gas" },
  ]);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...testData];
    list[index][name] = value;
    setTestData(list);
  };

  const handleServiceDelete = (e, index) => {
    const newtestData = testData.filter((v, i) => i !== index);
    setTestData(newtestData);
  };

  const handleServiceAdd = () => {
    setTestData([...testData, {}]);
  };

  const handleTestEquipmentChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...testEquipment];
    list[index][name] = value;
    setTestEquipment(list);
  };

  const previousHandler = () => {
    setResult(() => ({
      ...result,
      fourthPage: { ...data, testData, testEquipment },
    }));
    setShow1(false);
    setShow2(false);
    setShow3(true);
    setShowForm(false);
  };

  const submitHandler = () => {
    const serviceError = testEquipment
      .map((list) => {
        if (
          list.referenceInstrument &&
          list.make &&
          list.Concentration &&
          list.LOTNO &&
          list.LotExpiry
        ) {
          return true;
        } else {
          return false;
        }
      })
      .every((val) => val === true);
    const testEquipmentError = testData
      .map((list) => {
        if (
          list.GAS &&
          list.unit &&
          list.concentration &&
          list.freshAir &&
          list.spanGas &&
          list.error
        ) {
          return true;
        } else {
          return false;
        }
      })
      .every((val) => val === true);
    if (
      data !== "" &&
      data.temperature !== undefined &&
      data.relativeHumidity !== undefined &&
      data.temperature !== "" &&
      data.relativeHumidity !== "" &&
      serviceError &&
      testEquipmentError
    ) {
      setFormView(false);
      setPdfView(true);
      setResult(() => ({
        ...result,
        fourthPage: {
          ...data,
          testData,
          testEquipment,
        },
      }));
    } else {
      NotificationManager.warning(
        "Please complete the form",
        "Incomplete Form"
      );
    }
  };
  return (
    <>
      <div className={formView ? "block" : "hidden"}>
        <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white space-y-10">
          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">
              ENVIRONMENTAL CONDITION
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Temperature</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setData(() => ({ ...data, temperature: e.target.value }));
                  }}
                  placeholder="Enter Temperature"
                  className="input input-bordered w-full"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Relative Humidity</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setData(() => ({
                      ...data,
                      relativeHumidity: e.target.value,
                    }));
                  }}
                  placeholder="Enter Relative Humidity"
                  className="input input-bordered w-full"
                />
              </div>
            </div>
          </div>
          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">
              TRACEABILITY OF MASTER EQUIPMENT
            </h2>
            <div>
              {testEquipment.map((equipment, index) => (
                <div key={"equipment" + index} className="flex justify-between">
                  <div className="grid grid-cols-2 md:grid-cols-5 gap-6">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Reference Instrument</span>
                      </label>
                      <input
                        disabled
                        value={equipment.referenceInstrument || ""}
                        type="text"
                        name="referenceInstrument"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Make</span>
                      </label>
                      <input
                        value={equipment.make || ""}
                        type="text"
                        onChange={(e) => handleTestEquipmentChange(e, index)}
                        name="make"
                        placeholder="Enter Make"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Concentration</span>
                      </label>
                      <input
                        value={equipment.Concentration || ""}
                        type="text"
                        onChange={(e) => handleTestEquipmentChange(e, index)}
                        name="Concentration"
                        placeholder="Enter Concentration"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">LOT. No</span>
                      </label>
                      <input
                        value={equipment.LOTNO || ""}
                        type="text"
                        onChange={(e) => handleTestEquipmentChange(e, index)}
                        name="LOTNO"
                        placeholder="Enter LOT No ."
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">LOT. Expiry</span>
                      </label>
                      <input
                        value={equipment.LotExpiry || ""}
                        type="text"
                        onChange={(e) => handleTestEquipmentChange(e, index)}
                        name="LotExpiry"
                        placeholder="Enter LOT. Expiry"
                        className="input input-bordered w-full"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">TEST DATA </h2>
            <div>
              {testData.map((tdata, index) => (
                <div
                  key={"equipment-" + index}
                  className="flex justify-between"
                >
                  <div className="grid grid-cols-2 md:grid-cols-6 gap-6">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">GAS</span>
                      </label>
                      <input
                        value={tdata.GAS || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="GAS"
                        placeholder="Enter GAS"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Units</span>
                      </label>
                      <input
                        value={tdata.unit || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="unit"
                        placeholder="Enter Unit"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Concentration</span>
                      </label>
                      <input
                        value={tdata.concentration || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="concentration"
                        placeholder="Enter Concentration Value"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Fresh Air</span>
                      </label>
                      <input
                        value={tdata.freshAir || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="freshAir"
                        placeholder="Enter Fresh Air Value"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Span Gas</span>
                      </label>
                      <input
                        value={tdata.spanGas || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="spanGas"
                        placeholder="Enter Span Gas Value"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Error</span>
                      </label>
                      <input
                        value={tdata.error || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="error"
                        placeholder="Enter Error Value"
                        className="input input-bordered w-full"
                      />
                    </div>
                  </div>
                  {index === 0 ? null : (
                    <div className="flex flex-wrap content-center">
                      <img
                        src={close}
                        onClick={(e) => {
                          handleServiceDelete(e, index);
                        }}
                        className="h-6 w-6 cursor-pointer"
                        alt="Search Icon"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-end mb-2 gap-2">
              <button
                type="button"
                onClick={handleServiceAdd}
                className="inline-block mt-4 px-8 py-2 bg-green-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
              >
                <span>Add Gas</span>
              </button>
            </div>
          </div>

          <div className="flex justify-end gap-4">
            <button
              onClick={previousHandler}
              type="button"
              className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Previous
            </button>

            <button
              onClick={submitHandler}
              type="button"
              className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {pdfView ? (
        <div>
          <AlcoholDetectionPDF
            result={result}
            setFormView={setFormView}
            setPdfView={setPdfView}
            apiCall={apiCall}
            isCreatePage={true}
          />
        </div>
      ) : null}
    </>
  );
};
