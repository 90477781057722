import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import close from "../assets/images/close.png";
import PpmMonitor from "../pdf/ppm_monitor";

export const FifteenPPMMonitorForm = ({
  setResult,
  result,
  setShow1,
  setShow2,
  setShow3,
  setShowForm,
  apiCall,
}) => {
  const [formView, setFormView] = useState(true);
  const [pdfView, setPdfView] = useState(false);

  const [data, setData] = useState("");

  const [serviceList, setServiceList] = useState([{}]);
  const [testSolution, setTestSolution] = useState([
    { testFluid: "Fresh Water" },
    { testFluid: "Test Solution" },
  ]);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleTestSolutionChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...testSolution];
    list[index][name] = value;
    setTestSolution(list);
  };

  const handleServiceDelete = (e, index) => {
    const newServiceList = serviceList.filter((v, i) => i !== index);
    const newTestSolution = testSolution.filter((v, i) => i !== index + 1);
    setServiceList(newServiceList);
    setTestSolution(newTestSolution);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, {}]);
    setTestSolution([...testSolution, { testFluid: "Test Solution" }]);
  };

  const previousHandler = () => {
    setResult(() => ({
      ...result,
      fourthPage: { ...data, serviceList, testSolution },
    }));
    setShow1(false);
    setShow2(false);
    setShow3(true);
    setShowForm(false);
  };

  const submitHandler = async () => {
    const serviceError = serviceList
      .map((list) => {
        if (list.referenceInstrument && list.make && list.type && list.serial) {
          return true;
        } else {
          return false;
        }
      })
      .every((val) => val === true);
    const testSolutionError = testSolution
      .map((list) => {
        if (
          list.overboardValve &&
          list.alarm &&
          list.error &&
          list.measuredReading &&
          list.concentration
        ) {
          return true;
        } else {
          return false;
        }
      })
      .every((val) => val === true);
    if (
      data !== "" &&
      data.temperature !== undefined &&
      data.relativeHumidity !== undefined &&
      data.temperature !== "" &&
      data.relativeHumidity !== "" &&
      serviceError &&
      testSolutionError
    ) {
      setFormView(false);
      setPdfView(true);
      setResult(() => ({
        ...result,
        fourthPage: {
          ...data,
          serviceList,
          testSolution,
        },
      }));
    } else {
      NotificationManager.warning(
        "Please complete the form",
        "Incomplete Form"
      );
    }
  };
  return (
    <>
      <div className={formView ? "block" : "hidden"}>
        <div className="w-full px-6 py-8 rounded-lg shadow-lg bg-white space-y-10">
          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">
              ENVIRONMENTAL CONDITION
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Temperature</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setData(() => ({ ...data, temperature: e.target.value }));
                  }}
                  placeholder="Enter Temperature"
                  className="input input-bordered w-full"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Relative Humidity</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setData(() => ({
                      ...data,
                      relativeHumidity: e.target.value,
                    }));
                  }}
                  placeholder="Enter Relative Humidity"
                  className="input input-bordered w-full"
                />
              </div>
            </div>
          </div>

          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">
              TRACEABILITY OF REFERENCE EQUIPMENT
            </h2>
            <div>
              {serviceList.map((singleService, index) => (
                <div
                  key={"equipment-" + index}
                  className="flex justify-between"
                >
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Reference Instrument</span>
                      </label>
                      <input
                        value={singleService.referenceInstrument || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="referenceInstrument"
                        placeholder="Enter Reference Instrument"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Make</span>
                      </label>
                      <input
                        value={singleService.make || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="make"
                        placeholder="Enter Make"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Type</span>
                      </label>
                      <input
                        value={singleService.type || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="type"
                        placeholder="Enter Type"
                        className="input input-bordered w-full"
                      />
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Serial No.</span>
                      </label>
                      <input
                        value={singleService.serial || ""}
                        type="text"
                        onChange={(e) => handleServiceChange(e, index)}
                        name="serial"
                        placeholder="Enter Serial No."
                        className="input input-bordered w-full"
                      />
                    </div>
                  </div>
                  {index === 0 ? null : (
                    <div className="flex flex-wrap content-center">
                      <img
                        src={close}
                        onClick={(e) => {
                          handleServiceDelete(e, index);
                        }}
                        className="h-6 w-6 cursor-pointer"
                        alt="Search Icon"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-end mb-2 gap-2">
              <button
                type="button"
                onClick={handleServiceAdd}
                className="inline-block mt-4 px-8 py-2 bg-green-500 text-white font-medium text-xs leading-tight rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
              >
                <span>Add Reference Equipment</span>
              </button>
            </div>
          </div>

          <div>
            <h2 className="mt-2 text-lg font-bold text-gray-600">
              TEST DETAILS
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Test Fluid</span>
                </label>
                <input
                  type="text"
                  name="testSolution"
                  value="Fresh Water"
                  disabled
                  className="input input-bordered w-full !border-gray-300"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Concentration</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => handleTestSolutionChange(e, 0)}
                  name="concentration"
                  placeholder="Enter Concentration"
                  className="input input-bordered w-full"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Measured Reading</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => handleTestSolutionChange(e, 0)}
                  name="measuredReading"
                  placeholder="Enter Measured Reading"
                  className="input input-bordered w-full"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Error</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => handleTestSolutionChange(e, 0)}
                  name="error"
                  placeholder="Enter Error"
                  className="input input-bordered w-full"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Alarm</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => handleTestSolutionChange(e, 0)}
                  name="alarm"
                  placeholder="Enter Alarm"
                  className="input input-bordered w-full"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Overboard Valve</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => handleTestSolutionChange(e, 0)}
                  name="overboardValve"
                  placeholder="Enter Overboard Valve"
                  className="input input-bordered w-full"
                />
              </div>
            </div>
            {serviceList &&
              serviceList.map((singleService, index) => (
                <div key={"solution-" + (index + 1)}>
                  <h4 className="mt-8 uppercase font-bold pl-1">
                    {serviceList[index].referenceInstrument}
                  </h4>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Test Fluid</span>
                      </label>
                      <input
                        type="text"
                        name="testSolution"
                        value="Test Solution"
                        disabled
                        className="input input-bordered w-full !border-gray-300"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Concentration</span>
                      </label>
                      <input
                        value={testSolution[index + 1].concentration}
                        type="text"
                        onChange={(e) => handleTestSolutionChange(e, index + 1)}
                        name="concentration"
                        placeholder="Enter Concentration"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Measured Reading</span>
                      </label>
                      <input
                        value={testSolution[index + 1].measuredReading}
                        type="text"
                        onChange={(e) => handleTestSolutionChange(e, index + 1)}
                        name="measuredReading"
                        placeholder="Enter Measured Reading"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Error</span>
                      </label>
                      <input
                        value={testSolution[index + 1].error}
                        type="text"
                        onChange={(e) => handleTestSolutionChange(e, index + 1)}
                        name="error"
                        placeholder="Enter Error"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Alarm</span>
                      </label>
                      <input
                        value={testSolution[index + 1].alarm}
                        type="text"
                        onChange={(e) => handleTestSolutionChange(e, index + 1)}
                        name="alarm"
                        placeholder="Enter Alarm"
                        className="input input-bordered w-full"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text">Overboard Valve</span>
                      </label>
                      <input
                        value={testSolution[index + 1].overboardValve}
                        type="text"
                        onChange={(e) => handleTestSolutionChange(e, index + 1)}
                        name="overboardValve"
                        placeholder="Enter Overboard Valve"
                        className="input input-bordered w-full"
                      />
                    </div>
                  </div>
                </div>
              ))}
            <div></div>
          </div>
          <div className="flex justify-end gap-4">
            <button
              onClick={previousHandler}
              type="button"
              className="inline-block px-6 py-2.5 bg-blue-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Previous
            </button>

            <button
              onClick={submitHandler}
              type="button"
              className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Preview
            </button>
          </div>
        </div>
      </div>

      {pdfView ? (
        <div>
          <PpmMonitor
            result={result}
            setFormView={setFormView}
            setPdfView={setPdfView}
            apiCall={apiCall}
            isCreatePage={true}
          />
        </div>
      ) : null}
    </>
  );
};
