import React, { createContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import userDefault from "../../assets/images/profile-default.webp";

export const MyContext = createContext();

export const Dashboard = () => {
  const [value, setValue] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setName(userData.name);
    setDesignation(userData.designation);
  }, []);

  const gotoProfile = () => {
    try {
      navigate("/dashboard/profile");
    } catch (e) {
      console.log(e);
    }
  };

  const logout = () => {
    try {
      localStorage.removeItem("userData");
      navigate("/");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="bg-gray-50">
      <aside className="ml-[-100%] fixed z-10 top-0 pb-3 px-3 w-full flex flex-col justify-between h-screen border-r bg-white transition duration-300 md:w-4/12 lg:ml-0 lg:w-[25%] xl:w-[20%] 2xl:w-[15%]">
        <div>
          <div className="-mx-6 px-6 py-4">
            <Link to="/dashboard" className="flex justify-center" title="home">
              <img src={logo} className="max-h-14" alt="master logo" />
            </Link>
          </div>
          <div className="mt-2 text-center">
            <img
              src={userDefault}
              alt=""
              className="w-10 h-10 m-auto rounded-full object-cover lg:w-24 lg:h-24"
            />
            <h5 className="hidden mt-4 text-lg font-semibold text-gray-600 lg:block">
              {name}
            </h5>
            <span className="hidden text-sm text-gray-400 lg:block">
              {designation}
            </span>
          </div>
          <ul className="space-y-2 tracking-wide mt-8">
            <li>
              <Link
                to="/dashboard"
                aria-label="dashboard"
                className={
                  (value === "Dashboard"
                    ? "relative rounded-xl text-white bg-gradient-to-r from-sky-600 to-cyan-400"
                    : "text-gray-600 group") +
                  " px-4 py-3 flex items-center space-x-4"
                }
              >
                <svg className="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                    className="fill-current text-cyan-400 dark:fill-slate-600"
                  />
                  <path
                    d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                    className="fill-current text-cyan-200 group-hover:text-cyan-300"
                  />
                  <path
                    d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                    className="fill-current group-hover:text-sky-300"
                  />
                </svg>
                <span
                  className={
                    value === "Dashboard"
                      ? "-mr-1 font-medium"
                      : "group-hover:text-gray-700"
                  }
                >
                  Dashboard
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/certificates"
                className={
                  (value === "Certificates"
                    ? "relative rounded-xl text-white bg-gradient-to-r from-sky-600 to-cyan-400"
                    : "text-gray-600 group") +
                  " px-4 py-3 flex items-center space-x-4"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    className="fill-current text-gray-300 group-hover:text-cyan-300"
                    fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
                    clipRule="evenodd"
                  />
                  <path
                    className="fill-current text-gray-600 group-hover:text-cyan-600"
                    d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"
                  />
                </svg>
                <span
                  className={
                    value === "Certificates"
                      ? "-mr-1 font-medium"
                      : "group-hover:text-gray-700"
                  }
                >
                  Certificates
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/create-certificate"
                className={
                  (value === "Create Certificate"
                    ? "relative rounded-xl text-white bg-gradient-to-r from-sky-600 to-cyan-400"
                    : "text-gray-600 group") +
                  " px-4 py-3 flex items-center space-x-4"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    className="fill-current text-gray-300 group-hover:text-cyan-300"
                    fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
                    clipRule="evenodd"
                  />
                  <path
                    className="fill-current text-gray-600 group-hover:text-cyan-600"
                    d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"
                  />
                </svg>
                <span
                  className={
                    value === "Create Certificate"
                      ? "-mr-1 font-medium"
                      : "group-hover:text-gray-700"
                  }
                >
                  Generate Document
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/clients"
                className={
                  (value === "Clients" || value === "Create Client"
                    ? "relative rounded-xl text-white bg-gradient-to-r from-sky-600 to-cyan-400"
                    : "text-gray-600 group") +
                  " px-4 py-3 flex items-center space-x-4"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <g data-name="Layer 2">
                    <path
                      className="fill-current text-gray-300 group-hover:text-cyan-300"
                      d="M20 10a3 3 0 1 0-3 3 3 3 0 0 0 3-3zm-4 0a1 1 0 1 1 1 1 1 1 0 0 1-1-1zm1 4a5 5 0 0 0-3.56 1.48A6 6 0 0 0 2 18v3h20v-2a5 5 0 0 0-5-5zm-3 5a3 3 0 0 1 6 0z"
                    />
                    <circle cx="8" cy="7" r="4" />
                  </g>
                </svg>
                <span
                  className={
                    value === "Clients" || value === "Create Client"
                      ? "-mr-1 font-medium"
                      : "group-hover:text-gray-700"
                  }
                >
                  Clients
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/vessels"
                className={
                  (value === "Vessels" || value === "Create Vessel"
                    ? "relative rounded-xl text-white bg-gradient-to-r from-sky-600 to-cyan-400"
                    : "text-gray-600 group") +
                  " px-4 py-3 flex items-center space-x-4"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    className="fill-current text-gray-300 group-hover:text-cyan-300"
                    d="M13 2h-2c-1.1 0-2 .9-2 2v1.4c-1.2.5-2.2 1.8-2.4 3.3l-.3 2.5-2.6 1c-.3.1-.5.3-.6.6-.1.2-.1.5 0 .8L5.8 19c-.5.1-.8.5-.8 1 0 .6.4 1 1 1 .5 0 .6.1 1 .3v.1c.5.3 1 .6 2 .6 1.1 0 1.6-.4 2.1-.7.3-.2.5-.3.9-.3.5 0 .6.1 1 .3.4.3 1 .7 2.1.7 1 0 1.5-.3 2-.6l.1-.1c.3-.2.5-.3 1-.3.6 0 1-.4 1-1 0-.5-.3-.8-.8-1l2.7-5.4c.1-.3.1-.5 0-.8-.1-.3-.3-.5-.6-.6l-2.6-1-.3-2.5c-.2-1.6-1.2-2.8-2.4-3.4V4C15 2.9 14.1 2 13 2zm-2 2h2v1h-2V4zm5.5 8.8 2.2.8-3 6.2c-.3.1-.4.2-.7.2-.4 0-.6-.1-.9-.4-.4-.3-1-.8-2.1-.8s-1.7.4-2.1.8c-.3.3-.5.4-.9.4-.3 0-.4-.1-.6-.2l-3-6.2 2.1-.8 3.5-1.4V17c0 .6.4 1 1 1s1-.4 1-1v-5.5l3.5 1.3zM15.4 9l.2 1.3L12.4 9c-.2-.1-.5-.1-.7 0l-3.2 1.3.1-1.3c.2-1.2 1-2 2-2h2.9c.9 0 1.8.8 1.9 2z"
                  />
                </svg>
                <span
                  className={
                    value === "Vessels" || value === "Create Vessel"
                      ? "-mr-1 font-medium"
                      : "group-hover:text-gray-700"
                  }
                >
                  Vessels
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/users"
                className={
                  (value === "Users" || value === "Create User"
                    ? "relative rounded-xl text-white bg-gradient-to-r from-sky-600 to-cyan-400"
                    : "text-gray-600 group") +
                  " px-4 py-3 flex items-center space-x-4"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    className="fill-current text-gray-300 group-hover:text-cyan-300"
                    d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z"
                  />
                </svg>
                <span
                  className={
                    value === "Users" || value === "Create User"
                      ? "-mr-1 font-medium"
                      : "group-hover:text-gray-700"
                  }
                >
                  Users
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>

      <div className="ml-auto mb-6 lg:w-[75%] xl:w-[80%] 2xl:w-[85%]">
        <div className="sticky z-50 top-0 h-16 border-b bg-white lg:py-2.5">
          <div className="px-6 flex items-center justify-between space-x-4 2xl:container">
            <h5 hidden className="text-2xl text-gray-600 font-medium lg:block">
              {value}
            </h5>

            <div className="flex space-x-4">
              <button
                onClick={gotoProfile}
                aria-label="Profile"
                className="w-10 h-10 rounded-xl border bg-gray-100 focus:bg-gray-100 active:bg-gray-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-auto text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    fill="#000"
                    fillRule="evenodd"
                    d="M134 2009c-2.217 0-4.019-1.794-4.019-4s1.802-4 4.019-4 4.019 1.794 4.019 4-1.802 4-4.019 4m3.776.673a5.978 5.978 0 0 0 2.182-5.603c-.397-2.623-2.589-4.722-5.236-5.028-3.652-.423-6.75 2.407-6.75 5.958 0 1.89.88 3.574 2.252 4.673-3.372 1.261-5.834 4.222-6.22 8.218a1.012 1.012 0 0 0 1.004 1.109.99.99 0 0 0 .993-.891c.403-4.463 3.836-7.109 7.999-7.109s7.596 2.646 7.999 7.109a.99.99 0 0 0 .993.891c.596 0 1.06-.518 1.003-1.109-.385-3.996-2.847-6.957-6.22-8.218"
                    transform="translate(-124 -1999)"
                  />
                </svg>
              </button>
              <button
                aria-label="notification"
                className="w-10 h-10 rounded-xl border bg-gray-100 focus:bg-gray-100 active:bg-gray-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-auto text-gray-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                </svg>
              </button>
              <button
                onClick={logout}
                aria-label="logout"
                className="w-10 h-10 rounded-xl border bg-gray-100 focus:bg-gray-100 active:bg-gray-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 m-auto text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="min-h-screen">
          <MyContext.Provider value={{ value, setValue }}>
            <Outlet />
          </MyContext.Provider>
        </div>
      </div>
    </div>
  );
};
