import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

import { Login } from "./pages/login/Login";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { Home } from "./pages/home/Home";
import { CreateCertificate } from "./pages/create-certificate/CreateCertificate";
import CreateClient from "./pages/createClient/CreateClient";
import CreateVessel from "./pages/createVessel/CreateVessel";
import CreateUser from "./pages/createUser/CreateUser";
import Profile from "./pages/profile/Profile";
import ClientList from "./pages/clientList/ClientList";
import VesselList from "./pages/vesselList/VesselList";
import UserList from "./pages/userList/UserList";
import CertificatesList from "./pages/certificatesList/CertificatesList";

function App() {
  function RequireAuth({ children }) {
    let authenticated =
      localStorage.getItem("userData") !== null ? true : false;
    return authenticated === true ? children : <Navigate to="/" replace />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route index element={<Home />} />
          <Route
            path="/dashboard/create-certificate"
            element={<CreateCertificate />}
          />
          <Route path="/dashboard/create-client" element={<CreateClient />} />
          <Route path="/dashboard/create-vessel" element={<CreateVessel />} />
          <Route path="/dashboard/create-user" element={<CreateUser />} />
          <Route path="/dashboard/profile" element={<Profile />} />
          <Route path="/dashboard/clients" element={<ClientList />} />
          <Route path="/dashboard/vessels" element={<VesselList />} />
          <Route path="/dashboard/users" element={<UserList />} />
          <Route
            path="/dashboard/certificates"
            element={<CertificatesList />}
          />
        </Route>
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
