const BASE_URL = "https://master-server-415306.el.r.appspot.com/";
// const BASE_URL = "http://localhost:3000/";
async function postRequest(request) {
  const headers = new Headers({
    "Content-Type": "application/json",
    authorization: `Bearer ${request.token}`,
  });
  const fetchData = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(request.data),
  };
  const url = `${BASE_URL}${request.path}`;
  try {
    let response = await fetch(url, fetchData);

    let data = await response.json();
    return data;
  } catch (error) {
    console.log("API REQUEST FAILED");
  }
}

export { postRequest };
